import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { SchoolListProps } from './interface';
import axiosInstance from '../../../../../api/axiosInstance';

export const GetSchoolList = createAsyncThunk(
  'schooll/list',
  async ({
    konsumenId
  }: {
    konsumenId: string
  }) => { 
    const response = await axiosInstance.get(`/konsumen/sekolah/list/${konsumenId}`); 
    console.log(response);
    
    return response.data;
  }
);

// Redux slice
const ChatList = createSlice({
    name: 'SchoollList',
    initialState: {
      data: null as SchoolListProps | null,
      loading: false,
      error: null as string | null | undefined, 
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(GetSchoolList.pending, (state) => {
          state.loading = true;
          state.error = null;  
        })
        .addCase(GetSchoolList.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
        })
        .addCase(GetSchoolList.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;  
        });
    },
  });
  

export default ChatList.reducer;

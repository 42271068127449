import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface dataLoginState {
    phone: string;
    email: string
}

const initialState: dataLoginState = {
    phone: '',
    email: ''
};
const dataLogin = createSlice({
    name: 'dataLogin',
    initialState,
    reducers: {
        setPhone: (state, action: PayloadAction<string>) => {
            state.phone = action.payload;
        },
        setEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload;
        },
    },
});
export const { setPhone, setEmail } = dataLogin.actions;



export default dataLogin.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../..';

interface Distributor {
    distributorName: string ;
    typeVehicle: string ;
}

interface dataPayments {
    productName: string | null;
    storeName: string | null;
    ProductPict: string | undefined;
    ProductId: string | null;
    size: string | null;
    color: string | null;
    price: number | null;
    quantity: number | null;
    minQty: number;
    totaPrice: number ;
    ongkir: number[];
    distributor: Distributor[];
    DP: number | null;
    date: string | null;
    address: string | null;
    methodPayment: string | null;
    isInsurance: boolean;
    amountInsurance: number;
}

const initialState: dataPayments = {
    productName: '',
    storeName: '',
    ProductPict: '',
    ProductId: '',
    size: '',
    color: '',
    price: 0,
    quantity: 0,
    minQty: 0,
    totaPrice: 0,
    ongkir:[],
    distributor: [
        {
            distributorName: '',
            typeVehicle: ''
        }
    ],    DP: 0,
    date: '',
    address: '',
    methodPayment: '',
    isInsurance: false,
    amountInsurance: 0
}

const dataPayment = createSlice({
    name: 'distributor',
    initialState,
    reducers: {
        setProductName(state, action: PayloadAction<string>) {
            state.productName = action.payload;
        },
        setStoreName(state, action: PayloadAction<string>) {
            state.storeName = action.payload;
        },
        setProductPict(state, action: PayloadAction<string>) {
            state.ProductPict = action.payload;
        },
        setProductId(state, action: PayloadAction<string>) {
            state.ProductId = action.payload;
        },
        setSize(state, action: PayloadAction<string>) {
            state.size = action.payload;
        },
        setColor(state, action: PayloadAction<string>) {
            state.color = action.payload;
        },
        setPrice(state, action: PayloadAction<number>) {
            state.price = action.payload;
        },
        setQuantity(state, action: PayloadAction<number>) {
            state.quantity = action.payload;
            state.amountInsurance = state.quantity * 500
        },
        setMinQty(state, action: PayloadAction<number>) {
            state.minQty = action.payload;
        },
        setTotalPrice(state, action: PayloadAction<number>) {
            state.totaPrice = action.payload;
        },
        setOngkir(state, action: PayloadAction<number>) {
            if (state.ongkir) {
                state.ongkir.push(action.payload);
            } else {
                state.ongkir = [action.payload];
            }
        },
        addDistributor(state, action: PayloadAction<Distributor>) {
            state.distributor.push(action.payload);
        },
      
        setDP(state, action: PayloadAction<number>) {
            state.DP = action.payload;
        },
        setDate(state, action: PayloadAction<string>) {
            state.date = action.payload;
        },
        setAddress(state, action: PayloadAction<string>) {
            state.address = action.payload;
        },
        setMethodPayment(state, action: PayloadAction<string>) {
            state.methodPayment = action.payload;
        },
        setIsInsurance(state, action: PayloadAction<boolean>) {
            state.isInsurance = action.payload;
        }
    },
});

export const selectLastProductTotalPrice = (state: RootState): number => {
    const lastVendorIndex = state.cart.vendors.length - 1;
    if (lastVendorIndex < 0) return 0; // Jika tidak ada vendor, kembalikan null

    const lastVendor = state.cart.vendors[lastVendorIndex];
    const lastProductIndex = lastVendor.products.length - 1;
    if (lastProductIndex < 0) return 0; // Jika tidak ada produk, kembalikan null

    return lastVendor.products[lastProductIndex].total_price;
};

export const { setProductName, setStoreName, setProductPict, setProductId, setSize, setColor, setPrice, setQuantity, setMinQty, setTotalPrice,setOngkir, addDistributor, setDP, setDate, setAddress, setMethodPayment } = dataPayment.actions;
export default dataPayment.reducer;

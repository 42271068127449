import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { checklist } from "./interface";

const initialState: checklist = {
    DataToko: [],
    dataProduct: []
};

const checklistSlice = createSlice({
    name: 'checklist',
    initialState,
    reducers: {
        addToko(state, action: PayloadAction<string | string[]>) {
            if (Array.isArray(action.payload)) {
                action.payload.forEach((tokoId) => {
                    if (!state.DataToko.includes(tokoId)) {
                        state.DataToko.push(tokoId);
                    }
                });
            } else {
                if (!state.DataToko.includes(action.payload)) {
                    state.DataToko.push(action.payload);
                }
            }
        },
        removeToko(state, action: PayloadAction<string[]>) {
            state.DataToko = state.DataToko.filter((tokoId) => !action.payload.includes(tokoId));

            state.dataProduct = state.dataProduct.filter(
                (product) => !action.payload.includes(product.tokoId)
            );
        },
        addProduct(state, action: PayloadAction<{ tokoId: string; productId: string }[]>) {
            action.payload.forEach(({ tokoId, productId }) => {
                const productExists = state.dataProduct.some(
                    (product) => product.tokoId === tokoId && product.productId === productId
                );

                if (!productExists) {
                    state.dataProduct.push({ tokoId, productId });
                }
            });
        },
        removeProduct(state, action: PayloadAction<{ tokoId: string; productId: string }[]>) {
            action.payload.forEach(({ tokoId, productId }) => {
                state.dataProduct = state.dataProduct.filter(
                    (product) => !(product.tokoId === tokoId && product.productId === productId)
                );
            });
        }
    },
});

export const { addToko, removeToko, addProduct, removeProduct } = checklistSlice.actions;
export default checklistSlice.reducer;

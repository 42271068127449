import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UpdateProductQuantityPayload } from './interfaceReducers';
import { DisplayPayments } from '../../../../../../konsumen/pages/paymentUpdate/inteface/displayPayment';

interface dataForDisplayPayment {
  data: DisplayPayments | null
}

const initialState: dataForDisplayPayment = {
  data: null
};

const DisplayPayment = createSlice({
  name: 'display payment page',
  initialState,
  reducers: {
    setDisplayPaymentData(state, action: PayloadAction<DisplayPayments>) {
      state.data = action.payload;
    },
    setTotalSiswa(state, action: PayloadAction<number>) {
      if (state.data) {
        state.data.totalSiswa = action.payload; // Set total siswa
      }
    },
    setGrandTotal(state, action: PayloadAction<number>) {
      if (state.data) {
        state.data.grandTotal = action.payload;
      }
    },
    setSubsidiTotal(state, action: PayloadAction<number>) {
      if (state.data) {
        state.data.subsidiTotal = action.payload;
      }
    },
    editProductQuantity(state, action: PayloadAction<UpdateProductQuantityPayload>) {
      const { toko_id, product_id, newQty } = action.payload;
      if (state.data) {
        const targetToko = state.data.List.find(toko => toko.toko_idPayment === toko_id);
        if (targetToko) {
          const targetProduct = targetToko.product.find(prod => prod.id === product_id);
          if (targetProduct) {
            targetProduct.qty = newQty;
          } else {
            console.log(`gk ada product dengan id ${product_id}`);

          }
        } else {
          console.log(`gk ada toko dengan Id ${toko_id}`);

        }
      }
    },
    updateTglPengiriman(state, action: PayloadAction<{ toko_id: string; tglPengiriman: string }>) {
      const { toko_id, tglPengiriman } = action.payload;
      if (state.data) {
        const targetToko = state.data.List.find(toko => toko.toko_idPayment === toko_id);
        if (targetToko) {
          // Update tglPengiriman
          targetToko.tglPengiriman = tglPengiriman;
        } else {
          console.log(`gk ada toko dengan Id ${toko_id}`);
        }
      }
    },
    updateDistName(state, action: PayloadAction<{ toko_id: string; distName: string }>) {
      const { toko_id, distName } = action.payload;
      if (state.data) {
        const targetToko = state.data.List.find(toko => toko.toko_idPayment === toko_id);
        if (targetToko) {
          targetToko.distName = distName;
        } else {
          console.log(`gk ada toko dengan Id ${toko_id}`);
        }
      }
    },
    setInsurance(state, action: PayloadAction<{ toko_id: string; insurance: boolean }>) {
      const { toko_id, insurance } = action.payload;
      if (state.data) {
        // Cari toko berdasarkan toko_id
        const targetToko = state.data.List.find(toko => toko.toko_idPayment === toko_id);
        if (targetToko) {
          // Update nilai Insurance pada toko
          targetToko.Insurance = insurance;
        } else {
          console.log(`Toko dengan Id ${toko_id} tidak ditemukan`);
        }
      }
    },
    setProteksi(state, action: PayloadAction<{ toko_id: string; product_id: string; proteksi: boolean }>) {
      const { toko_id, product_id, proteksi } = action.payload;
      if (state.data) {
        const targetToko = state.data.List.find(toko => toko.toko_idPayment === toko_id);
        if (targetToko) {
          const targetProduct = targetToko.product.find(prod => prod.id === product_id);
          if (targetProduct) {
            targetProduct.protection = proteksi;
          } else {
            console.log(`gk ada product dengan id ${product_id}`);
          }
        } else {
          console.log(`gk ada toko dengan Id ${toko_id}`);
        }
      }
    },
    resetDisplayPaymentData(state) {
      state.data = null;
    }
  },
});

export const {
  setProteksi,
  setInsurance,
  setGrandTotal,
  setTotalSiswa,
  updateDistName,
  setSubsidiTotal,
  updateTglPengiriman,
  editProductQuantity,
  setDisplayPaymentData,
  resetDisplayPaymentData,
} = DisplayPayment.actions;

export default DisplayPayment.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DistDetail {
    DistId: string
    DistName: string;
    DistType: string;
    IdKendaraaan: string
    ongkir: number;
    idJenisKendaraan: string
    idJenisLayanan: string
}
interface varian {
    nama_varian: string;
    nilai_varian: string;
    harga: number;
}
interface tersedia {
    message: string;
    value: boolean
}
interface ListProduct {
    proteksi: boolean
    _id: string;
    cartId: string;
    total_price_cart: number;
    total_price: number;
    total_stok: number;
    diskon: number;
    price: number;
    quantity: number;
    tersedia: tersedia;
    note: string;
    minimalOrder: number;
    name_product: string;
    image_product: string[];
    berat: number;
    panjang: number;
    tinggi: number;
    lebar: number;
    varian: varian[]
    InsuranceTotal: number
    isInsurance: boolean
    isActive: boolean
}
interface CartProduct {
    nama_toko: string;
    userId: string;
    _id: string;
    idDist: string
    products: ListProduct[];
    distributor: DistDetail;
    tglPengiriman: string | null
}
interface Keranjang {
    keranjang : CartProduct[]
}
const initialState: Keranjang = {
    keranjang: []
};
const keranjangSlice = createSlice({
    name: 'keranjang',
    initialState,
    reducers: {
        addToKeranjang: (state, action: PayloadAction<CartProduct>) => {
            const updatedProducts = action.payload.products.map(product => ({
                ...product,
                isActive: false
            }));
            state.keranjang.push({ ...action.payload, products: updatedProducts });
        },
        addMultipleToKeranjang: (state, action: PayloadAction<CartProduct[]>) => {
            state.keranjang = [...state.keranjang, ...action.payload];
        },
        setProductActiveById: (
            state, 
            action: PayloadAction<{ productId: string; cartId: string; isActive: boolean }>
        ) => {
            state.keranjang = state.keranjang.map(cart => {
                if (cart.userId === action.payload.cartId) {
                    const updatedProducts = cart.products.map(product =>
                        product._id === action.payload.productId ? { ...product, isActive: action.payload.isActive } : product
                    );
                    return { ...cart, products: updatedProducts };
                }
                return cart;
            });
        },
        //---------------------------------------------------bagian ini mesti diperiksa lagi karena perubahan di display masi belum realtime-----------------------------------
        updateProductQuantityById: (
            state,
            action: PayloadAction<{ productId: string; cartId: string; quantity: number }>
        ) => {
            state.keranjang = state.keranjang.map(cart => {
                if (cart.userId === action.payload.cartId) {
                    const updatedProducts = cart.products.map(product =>
                        product._id === action.payload.productId ? { ...product, quantity: action.payload.quantity } : product
                    );
                    return { ...cart, products: updatedProducts };
                }
                return cart;
            });
        }
        //---------------------------------------------------bagian ini mesti diperiksa lagi karena perubahan di display masi belum realtime-----------------------------------
        
    },
});

export const selectTotalActiveProductsValue = (state: { keranjang: Keranjang }): number => {
    return state.keranjang.keranjang.reduce((total, cart) => {
        return total + cart.products.reduce((cartTotal, product) => {
            if (product.isActive) {
                return cartTotal + (product.quantity * product.total_price);
            }
            return cartTotal;
        }, 0);
    }, 0);
};


export const { addToKeranjang, addMultipleToKeranjang, setProductActiveById, updateProductQuantityById } = keranjangSlice.actions;
export default keranjangSlice.reducer;
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GrafikPenghasilan, RiwayatKeuangan, TotalPenghasilan } from '../../../../../distributor/types';
import axiosInstance from '../../../../../api/axiosInstance';

interface DistributtorState {
    dataPenghasilan: TotalPenghasilan;
    grafikPenghasilan: GrafikPenghasilan[];
    kalkulasiPenghasilan: TotalPenghasilan;
    riwayatKeuangan: RiwayatKeuangan[];
    riwayatKeuanganMount: RiwayatKeuangan[];
    loading: boolean;
    error: string | null;
}

const initialState: DistributtorState = {
    riwayatKeuanganMount: [],
    riwayatKeuangan: [],
    grafikPenghasilan: [],
    kalkulasiPenghasilan: {
        total_penghasilan: 0,
        total: {
            total_produk: 0,
            total_quantity: 0,
        },
    },
    dataPenghasilan: {
        total_penghasilan: 0,
        total: {
            total_produk: 0,
            total_quantity: 0,
        },
    },
    loading: false,
    error: null,
};

export const fetchTotalPenghasilan = createAsyncThunk<TotalPenghasilan, void>(
    'Penghasilan/fetchTotalPenghasilan',
    async () => {
        try {
            const response = await axiosInstance.get(`/penghasilan/distributor/total`);
            return response.data;
        } catch (error) {
            console.error('Error fetching penghasilan total:', error);
            throw error;
        }
    },
);

export const fetchKalkulasiPenghasilan = createAsyncThunk<TotalPenghasilan, string>(
    'Penghasilan/fetchKalkulasiPenghasilan',
    async (queryParams: string) => {
        try {
            const response = await axiosInstance.get(`/penghasilan/distributor?${queryParams}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching kalkulasi total:', error);
            throw error;
        }
    },
);
export const fetchGrafikPenghasilan = createAsyncThunk<GrafikPenghasilan[], string>(
    'Penghasilan/fetchGrafikPenghasilan',
    async (queryParams: string) => {
        try {
            const response = await axiosInstance.get(`/penghasilan/distributor/grafik-keuangan?${queryParams}`);
            return response.data.data;
        } catch (error) {
            console.error('Error fetching grafik:', error);
            throw error;
        }
    },
);
export const fetchRiwayatKeuangan = createAsyncThunk<RiwayatKeuangan[], void>(
    'Penghasilan/fetchRiwayatKeuangan',
    async () => {
        try {
            const response = await axiosInstance.get(`/penghasilan/distributor/riwayat-keuangan`);
            return response.data.data;
        } catch (error) {
            console.error('Error fetching riwayat semua:', error);
            throw error;
        }
    },
);
export const fetchRiwayatKeuanganMount = createAsyncThunk<RiwayatKeuangan[], string>(
    'Penghasilan/fetchRiwayatKeuanganMount',
    async (queryParams: string) => {
        try {
            const response = await axiosInstance.get(`/penghasilan/distributor/riwayat-keuangan?${queryParams}`);
            return response.data.data;
        } catch (error) {
            console.error('Error fetching riwayat per bulan:', error);
            throw error;
        }
    },
);

const PenghasilanSlice = createSlice({
    name: 'penghasilan',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTotalPenghasilan.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchTotalPenghasilan.fulfilled, (state, action: PayloadAction<TotalPenghasilan>) => {
                state.loading = false;
                state.dataPenghasilan = action.payload;
            })
            .addCase(fetchTotalPenghasilan.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch total penghasilan';
                console.error('Error fetching penghasilan:', action.error.message);
            })
            .addCase(fetchKalkulasiPenghasilan.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchKalkulasiPenghasilan.fulfilled, (state, action: PayloadAction<TotalPenghasilan>) => {
                state.loading = false;
                state.kalkulasiPenghasilan = action.payload;
            })
            .addCase(fetchKalkulasiPenghasilan.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch kalkulasi penghasilan';
                console.error('Error fetching penghasilan:', action.error.message);
            })
            .addCase(fetchGrafikPenghasilan.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchGrafikPenghasilan.fulfilled, (state, action: PayloadAction<GrafikPenghasilan[]>) => {
                state.loading = false;
                state.grafikPenghasilan = action.payload;
            })
            .addCase(fetchGrafikPenghasilan.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch grafik';
                console.error('Error fetching grafik:', action.error.message);
            })
            .addCase(fetchRiwayatKeuangan.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchRiwayatKeuangan.fulfilled, (state, action: PayloadAction<RiwayatKeuangan[]>) => {
                state.loading = false;
                state.riwayatKeuangan = action.payload;
            })
            .addCase(fetchRiwayatKeuangan.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch riwayat keuangan';
                console.error('Error fetching riwayat keuangan:', action.error.message);
            })
            .addCase(fetchRiwayatKeuanganMount.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchRiwayatKeuanganMount.fulfilled, (state, action: PayloadAction<RiwayatKeuangan[]>) => {
                state.loading = false;
                state.riwayatKeuanganMount = action.payload;
            })
            .addCase(fetchRiwayatKeuanganMount.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch riwayat perbulan';
                console.error('Error fetching riwayat per bulan:', action.error.message);
            });
    },
});

export const PenghasilanService = { action: PenghasilanSlice.actions, fetchTotalPenghasilan };
const penghasilanReducer = PenghasilanSlice.reducer;
export default penghasilanReducer;

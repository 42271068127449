import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DetailUser {
    UserId: string | null;
    IdKonsumen: string | null;
    Address: string | null;
    OfficeTelepon: string | null;
    Email: string;
    Phone: string | null;
    ProfilePict: string;
    Jenis: string | null;
    Tgl: string | null;
    IsValid: boolean;
    DataSekolah: boolean;
    UserName: string;
    Poin: number | null;
    Saldo: number | null;
}

const initialState: DetailUser = {
    UserId: '',
    IdKonsumen: '',
    Address: '',
    OfficeTelepon: '',
    Email: '',
    Phone: '',
    ProfilePict: '',
    Jenis: null,
    Tgl: null,
    IsValid: true,
    DataSekolah: false,
    UserName: '',
    Poin: 0,
    Saldo: 0,
};

const detailUser = createSlice({
    name: 'DetailUser',
    initialState,
    reducers: {
        setUserId(state, action: PayloadAction<string>) {
            state.UserId = action.payload;
        },
        setIdKonsumen(state, action: PayloadAction<string>) {
            state.IdKonsumen = action.payload;
        },
        setAddress(state, action: PayloadAction<string>) {
            state.Address = action.payload;
        },
        setOfficeTelepon(state, action: PayloadAction<string>) {
            state.OfficeTelepon = action.payload;
        },
        setEmail(state, action: PayloadAction<string>) {
            state.Email = action.payload;
        },
        setPhone(state, action: PayloadAction<string>) {
            state.Phone = action.payload;
        },
        setProfilePict(state, action: PayloadAction<string>) {
            state.ProfilePict = action.payload;
        },
        setJenis(state, action: PayloadAction<string>) {
            state.Jenis = action.payload;
        },
        setTgl(state, action: PayloadAction<string>) {
            state.Tgl = action.payload;
        },
        setIsValid(state, action: PayloadAction<boolean>) {
            state.IsValid = action.payload;
        },
        setDataSekolah(state, action: PayloadAction<boolean>) {
            state.DataSekolah = action.payload;
        },
        setUserName(state, action: PayloadAction<string>) {
            state.UserName = action.payload;
        },
        setPoin(state, action: PayloadAction<number>) {
            state.Poin = action.payload;
        },
        setTotalPrice(state, action: PayloadAction<number>) {
            state.Saldo = action.payload;
        },
    },
});

export const {
    setUserId,
    setIdKonsumen,
    setAddress,
    setOfficeTelepon,
    setEmail,
    setPhone,
    setProfilePict, setJenis, setTgl, setIsValid,
    setPoin,
    setTotalPrice,
    setUserName,
    setDataSekolah
} = detailUser.actions;
export default detailUser.reducer;

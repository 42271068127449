import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import { persistReducer, persistStore } from 'redux-persist';
import cartSlice from './reducers/cartSlice';
import storage from 'redux-persist/es/storage';
import roleSlices from './reducers/roleSlices';
import Cart from './reducers/dataPayment/Cart';
import UserInfo from './reducers/userInfo/index';
import nonSubsidi from './reducers/SubsidiPayment';
import FeeAps from './reducers/dataPayment/FeeAps';
import formsRoleSlices from './reducers/formsRoleSlices';
import RegisterUserReducer from './reducers/register/index';
import AddProductReducer from './reducers/vendor/addVendor';
import methodsReducer from './reducers/paymentMethod/index';
import dataPayment from './reducers/dataPayment/dataPayment';
import sekolahList from './reducers/ListSchoolData/SchoolList';
import AddProductVarianReducer from './reducers/vendor/vendorVarian';
import RegisterKonsumenReducer from './reducers/register/email/individu';
import categorySlice from './reducers/category/categorySlice';
import formIndividualSlices from './reducers/formsIndividualSlice';
import distributorSlices from './reducers/distributor/distributorSlice';
import detailUser from './reducers/Konsumen/detailKonsumen/detailKonsumen';
import KonsumenDetail from './reducers/Konsumen/detailKonsumen/konsumenDatas';
import RegisterKonsumenInstansiReducer from './reducers/register/email/perusahaan';
import RegisterVendorReducer from './reducers/vendor/registerVendor';
import IndividualVendorReducer from './reducers/vendor/individualVendor';
import PerusahaanVendorReducer from './reducers/vendor/perusahaanVendor';
import AlamatPerusahaanReducer from './reducers/register/email/PTKonsumen';
import AlamatPJReducer from './reducers/register/email/PJKonsumen';
import AddBioVendorReducer from './reducers/vendor/addBioVendor';
import IndividualDistributorReducer from './reducers/distributor/IndividualsDistributor';
import RegisterDistributorReducer from './reducers/distributor/RegisterDistributor';
import PerusahaanDistributorReducer from './reducers/distributor/PerusahaanDistributor';
import AddBioDistributorReducer from './reducers/distributor/AddBioDistributor';
import keranjangSlice from './reducers/KeranjangBelanja';
import NotificationVendorReducer from './reducers/vendor/notificationVendor';
import KendaraanIndividuReducer from './reducers/distributor/individu/kendaraanIndividu';
import manajemenPengemudiReducer from './reducers/distributor/perusahaan/manajemenPengemudi';
import manajemenKendaraanReducer from './reducers/distributor/perusahaan/manajemenKendaraan';
import pesananProdukReducer from './reducers/distributor/pesanan/pesananProduk';
import PesananVendorReducer from './reducers/vendor/pesananVendor';
import prosesPengirimanReducer from './reducers/distributor/prosesPengiriman/prosesPengiriman';
import transactionReducer from './reducers/transactionDetail';
import detailProductReducer from './reducers/Konsumen/productDetail';
import reviewProductList from './reducers/Konsumen/ReviewList';
import cartList from './reducers/Konsumen/cart';
import KonsumenNotification from './reducers/Konsumen/notification';
import shipmentTracking from './reducers/Konsumen/lacakPengiriman';
import ProductInRadius from './reducers/Konsumen/productInRadius';
import transaksiDetail from './reducers/Konsumen/detailTransaksi';
import DataPutPassword from './reducers/Konsumen/ForgotPassword';
import DisplayPayment from './reducers/Konsumen/payment/displayPayment';
import PayloadPayment from './reducers/Konsumen/payment/bodyPayment';
import chatDetail from './reducers/Konsumen/chat/chatDetail';
import ChatList from './reducers/Konsumen/chat/chatList/index';
import VendorDetail from './reducers/Konsumen/detailToko';
import DevicesLoginList from './reducers/Konsumen/LoginActivity';
import DellAccountReason from './reducers/Konsumen/auth/dellAccountReason';
import AddressSchoolList from './reducers/Konsumen/AddressSchoolList';
import DataLogin from './reducers/Konsumen/auth/login';
import distributtorReducer from './reducers/distributor/distributtor';
import userVerifReducer from './reducers/users/verifUsers';
import ContactChange from './reducers/users/changeContact';
import Whistlist from './reducers/Konsumen/wishlist';
import StoreRekomendation from './reducers/Konsumen/storeRekomendation';
import StoreSubscriptionRekomendation from './reducers/Konsumen/storeSubscriptionRekomendation/index';
import StoreSubscriptionList from './reducers/Konsumen/storeSubscriptionList/index';
import DataSearch from './reducers/Konsumen/searchData';
import SearchDataProduct from './reducers/Konsumen/searchDataProduct';
import SchoolList from './reducers/Konsumen/schoolList';
import tokoVendorReducer from './reducers/vendor/Toko/detailToko';
import ratingSliceReducer from './reducers/distributor/rating/ratingSlice';
import WaitingPaymentData from './reducers/Konsumen/orderDetail';
import CartUpdate from './reducers/Konsumen/cart';
import Recurring from './reducers/Konsumen/transaksi/recurring';
import CheckListCart from './reducers/Konsumen/cart/checkList';
import CategoryList from './reducers/Konsumen/categoryList';
import DetailAddress from './reducers/Konsumen/addressDetail';
import analisisLayananReducer from './reducers/distributor/analisis/analisisSlice';
import UlasanVendorReducer from './reducers/vendor/Ulasan/ulasanProduk';
import penghasilanReducer from './reducers/distributor/transaksi/transaksiDistri';
import analisisVendorReducer from './reducers/vendor/Analisis';
import TransactionDetail from './reducers/Konsumen/transaksi/detailTransaksi';
import riwayatPengirimanReducer from './reducers/distributor/riwayatPengiriman/riwayatPengirimanSlice';
import vendorDetailReducer from './reducers/vendor/pengaturanAkun';
import ProdusenRegistIndividual from './reducers/Produsen/Auth/Individual/index';
import alamatVendorReducer from './reducers/vendor/AlamatBioData';
import alamatUserReducer from './reducers/users/address/addressSlice';
import LoginInfoReducer from './reducers/users/loginInfo/loginSlice';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: [
        'cart',
        'kendaraanIndividu',
        'FeeAps',
        'RegisterUser',
        'detailUser',
        'PaymentMethods',
        'UserInfo',
        'DetailKonsumen',
        'transaction',
        'DisplayPayment',
        'PayloadPayment',
        'notificationVendor',
        'SchoolList',
        'perusahaanVendor',
        'registerVendor',
    ],
};

const rootReducer = combineReducers({
    UserInfo,
    DataPutPassword,
    KonsumenNotification,
    DetailKonsumen: KonsumenDetail,
    ProductInRadius,
    DetailProduct: detailProductReducer,
    CartUpdate,
    CheckListCart,
    VendorDetail,
    DisplayPayment,
    PayloadPayment,
    DetailReview: reviewProductList,
    CartList: cartList,
    DetailTransaction: transaksiDetail, // redux lama
    transaction: transactionReducer,
    shipmentTracking,
    chatDetail,
    ChatList,
    CategoryList,
    DetailAddress,
    DataLogin,
    AddressSchoolList,
    DevicesLoginList,
    DellAccountReason,
    Whistlist,
    TransactionDetail,
    Recurring,
    StoreRekomendation,
    StoreSubscriptionRekomendation,
    StoreSubscriptionList,
    DataSearch,
    SearchDataProduct,
    SchoolList,
    WaitingPaymentData,
    //---user---
    ContactChange,

    roles: roleSlices,
    formsRegister: formsRoleSlices,
    formsIndividual: formIndividualSlices,
    carts: cartSlice,
    distributor: distributorSlices,
    categorySlice,
    dataPayment,
    NonSubsidiPayment: nonSubsidi,
    FeeAps,
    detailUser: detailUser,
    listSekolah: sekolahList,
    cart: Cart,
    PaymentMethods: methodsReducer,
    Keranjang: keranjangSlice,

    productNotVarian: AddProductReducer,
    productVarian: AddProductVarianReducer,
    RegisterUser: RegisterUserReducer,
    RegisterKonsumen: RegisterKonsumenReducer,
    RegisterKonsumenInstitusi: RegisterKonsumenInstansiReducer,
    PerusahaanKonsumen: AlamatPerusahaanReducer,
    PJKonsumen: AlamatPJReducer,
    registerVendor: RegisterVendorReducer,
    individualVendor: IndividualVendorReducer,
    perusahaanVendor: PerusahaanVendorReducer,
    addingBioVendor: AddBioVendorReducer,
    notificationVendor: NotificationVendorReducer,
    pesananVendor: PesananVendorReducer,
    tokoVendor: tokoVendorReducer,
    ulasanVendor: UlasanVendorReducer,
    analisisVendor: analisisVendorReducer,
    vendorDetail: vendorDetailReducer,
    alamatVendor: alamatVendorReducer,

    // distributor
    distributtorDetail: distributtorReducer,
    individualDistributor: IndividualDistributorReducer,
    registerDistributor: RegisterDistributorReducer,
    perusahaanDistributor: PerusahaanDistributorReducer,
    addBioDistributor: AddBioDistributorReducer,
    pesananProduk: pesananProdukReducer,
    kendaraanIndividu: KendaraanIndividuReducer,
    manajemenPengemudi: manajemenPengemudiReducer,
    manajemenKendaraan: manajemenKendaraanReducer,
    prosesPengiriman: prosesPengirimanReducer,
    verifUser: userVerifReducer,
    ratingList: ratingSliceReducer,
    analisisLayanan: analisisLayananReducer,
    transaksiDistri: penghasilanReducer,
    riwayatPengiriman: riwayatPengirimanReducer,

    //---user---
    listAlamatUser: alamatUserReducer,
    loginInfo: LoginInfoReducer,

    //redux Produsen
    ProdusenRegistIndividual,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
        }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;

import { createAsyncThunk, createSlice, isRejectedWithValue, PayloadAction } from '@reduxjs/toolkit';
import { ProductAnalisisState, RingkasanPerformaState, GrafikPenghasilanState} from '../../../../../Vendor/types';
import axiosInstance from '../../../../../api/axiosInstance';
interface AnalisisState {
    datasProduct: ProductAnalisisState[];
    datasPerforma: RingkasanPerformaState;
    datasGrafik: GrafikPenghasilanState;
    loading: boolean;
    error: string | null;
}
const initialState: AnalisisState = {
    datasProduct: [],
    datasPerforma: {
        kunjungan_toko: 0,
        total_penjualan: {
            total_produk: 0,
            total_quantity: 0,
        },
        daftar_keinginan: 0,
        tayangan_product: 0,
    },
    datasGrafik: {
        data: [
            {
                bulan: '',
                totalPenghasilan: 0,
            },
        ],
        persen: 0,
    },
    loading: false,
    error: null,
};

export const getProductPopuler = createAsyncThunk<ProductAnalisisState[], void>(
    'AnalisisState/getProductPopuler',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get(`/vendor/toko/analisis/produk-populer`);
            return response.data.datas;
        } catch (error: any) {
            console.error('Error fetching getProductPopuler:', error);
            return rejectWithValue(error.response?.data?.message || 'Failed to fetch popular products');
        }
    },
);

export const getRingkasanPerforma = createAsyncThunk<RingkasanPerformaState, void>(
    'AnalisisState/getDatasPerforma',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get(`/vendor/toko/analisis/ringkasan`);
            return response.data;
        } catch (error: any) {
            console.error('Error fetching getDatasPerforma:', error);
            return rejectWithValue(error.response?.data?.message || 'Failed to fetch getDatasPerforma');
        }
    },
);

export const constructQueryYear = (params: { tab: any }) => {
    const { tab } = params;
    let selectedYear = '';
    if (tab) selectedYear += `${tab}`;
    return selectedYear;
};

export const getDataIncomeGraph = createAsyncThunk<GrafikPenghasilanState, any>(
    'AnalisisState/getDataIncomeGraph',
    async (selectedYear, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get(`/penghasilan/grafik-keuangan?tahun=${selectedYear}`);
            return response.data;
        } catch (error: any) {
            console.error('Error fetching getDataIncomeGraph:', error);
            return rejectWithValue(error.response?.data?.message || 'Failed to fetch getDataIncomeGraph');
        }
    },
);

const analisisVendorSlice = createSlice({
    name: 'analisisVendor',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getProductPopuler.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getProductPopuler.fulfilled, (state, action: PayloadAction<ProductAnalisisState[]>) => {
                state.loading = false;
                state.datasProduct = action.payload;
            })
            .addCase(getProductPopuler.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload || 'Failed to fetch popular products';
                console.error('Error fetching details:', action.payload);
            })
            .addCase(getRingkasanPerforma.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getRingkasanPerforma.fulfilled, (state, action: PayloadAction<RingkasanPerformaState>) => {
                state.loading = false;
                state.datasPerforma = action.payload;
            })
            .addCase(getRingkasanPerforma.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload || 'Failed to fetch ringkasan product';
                console.error('Error fetching ringkasan product:', action.payload);
            })
            .addCase(getDataIncomeGraph.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getDataIncomeGraph.fulfilled, (state, action: PayloadAction<GrafikPenghasilanState>) => {
                state.loading = false;
                state.datasGrafik = action.payload;
            })
            .addCase(getDataIncomeGraph.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload || 'Failed to fetch income graph';
                console.error('Error fetching income graph:', action.payload);
            });
    },
});

export const analisisVendorService = { ...analisisVendorSlice.actions, getProductPopuler, getRingkasanPerforma, getDataIncomeGraph };
export const analisisVendorReducer = analisisVendorSlice.reducer;
export default analisisVendorReducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DetailTransaction } from '../../../../konsumen/pages/profile/menu/daftarTransaksiList/detailTransaksiList/interface';

interface TransactionState {
    transaction: DetailTransaction | null;
}
const initialState: TransactionState = {
    transaction: null,
};
const transactionSlice = createSlice({
    name: 'transaction',
    initialState,
    reducers: {
        setTransaction(state, action: PayloadAction<DetailTransaction>) {
            state.transaction = action.payload; 
        },
        clearTransaction(state) {
            state.transaction = null; 
        },
    },
});
export const { setTransaction, clearTransaction } = transactionSlice.actions;
export default transactionSlice.reducer;

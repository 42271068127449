import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RegisterVendorState } from '../../interfaces';

const initialState: RegisterVendorState = {
    emailVendor: '',
    handphoneVendor: '',
    selectRoles: '',
    passwordVendor: '',
    idOtpVendor: '',
    idPhone: '',
    phoneOTP: '',
    emailOTP: '',
    idEmail: '',
};

const RegisterVendorSlice = createSlice({
    name: 'registerVendor',
    initialState,
    reducers: {
        onChangeFields: (state, action: PayloadAction<{ name: keyof RegisterVendorState; value: string }>) => {
            const { name, value } = action.payload;
            (state[name] as string) = value;
        },
        getEmailVendor: (state, action: PayloadAction<string>) => {
            state.emailVendor = action.payload;
        },
        setRoleVendor: (state, action: PayloadAction<string>) => {
            state.selectRoles = action.payload;
        },
        setPasswordVendor: (state, action: PayloadAction<string>) => {
            state.passwordVendor = action.payload;
        },
        getIdOtpVendor: (state, action: PayloadAction<string>) => {
            state.idOtpVendor = action.payload;
        },
        getIdPhone: (state, action: PayloadAction<string>) => {
            state.idPhone = action.payload;
        },
        getIdEmail: (state, action: PayloadAction<string>) => {
            state.idEmail = action.payload;
        },
        getEmailOTP: (state, action: PayloadAction<string>) => {
            state.emailOTP = action.payload;
        },
        getPhoneOTP: (state, action: PayloadAction<string>) => {
            state.phoneOTP = action.payload;
        },
    },
});

export const RegisterVendorService = { action: RegisterVendorSlice.actions };
const RegisterVendorReducer = RegisterVendorSlice.reducer;
export default RegisterVendorReducer;
export const isKeyofRegisterVendor = (key: any): key is keyof RegisterVendorState => {
    return ['emailVendor', 'handphoneVendor'].includes(key);
};

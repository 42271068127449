import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NoRatingDistri, RatingDistri } from '../../../../../distributor/types';
import axiosInstance from '../../../../../api/axiosInstance';
interface RatingState {
    ratingList: RatingDistri[];
    noRatingList: NoRatingDistri[];
    loading: boolean;
    error: string | null;
}

const initialState: RatingState = {
    noRatingList: [],
    ratingList: [],
    loading: false,
    error: null,
};

export const fetchRatingList = createAsyncThunk<RatingDistri[], void>('RatingList/fetchRatingList', async () => {
    try {
        const response = await axiosInstance.get(`/review-distributor/list`);
        return response.data.datas;
    } catch (error) {
        console.error('Error fetching rating:', error);
        throw error;
    }
});

export const fetchNoRatingList = createAsyncThunk<NoRatingDistri[], void>('RatingList/fetchNoRatingList', async () => {
    try {
        const response = await axiosInstance.get(`/review-distributor/tidak-review`);
        return response.data.data;
    } catch (error) {
        console.error('Error fetching no reting:', error);
        throw error;
    }
});

const RatingSlice = createSlice({
    name: 'ratingDistri',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder

            .addCase(fetchRatingList.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchRatingList.fulfilled, (state, action: PayloadAction<RatingDistri[]>) => {
                state.loading = false;
                state.ratingList = action.payload;
            })
            .addCase(fetchRatingList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch rating list';
                console.error('Error fetching rating:', action.error.message);
            })
            .addCase(fetchNoRatingList.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchNoRatingList.fulfilled, (state, action: PayloadAction<NoRatingDistri[]>) => {
                state.loading = false;
                state.noRatingList = action.payload;
            })
            .addCase(fetchNoRatingList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch no rating list';
                console.error('Error fetching no rating:', action.error.message);
            });
    },
});

export const RatingService = { action: RatingSlice.actions, fetchRatingList };
const ratingSliceReducer = RatingSlice.reducer;
export default ratingSliceReducer;

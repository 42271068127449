import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../../../api/axiosInstance';
import { feeApsProps } from './interfaceFee';

export const getFeeApps = createAsyncThunk(
    'order/detail',
    async () => {
        const response = await axiosInstance.get(`/biaya_tetap/list`);
        return response.data;
    }
);

// Redux slice
const FeeApps = createSlice({
    name: 'feeApps',
    initialState: {
        data: null as feeApsProps | null,
        loading: false,
        error: null as string | null | undefined,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getFeeApps.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getFeeApps.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(getFeeApps.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});


export default FeeApps.reducer;

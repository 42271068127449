import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axiosInstance from '../../../../../api/axiosInstance';
import { ReviewUlasan } from '../../../../../Vendor/types';
interface DataReviewUlasanState {
    dataReviewUlasan: ReviewUlasan[];
    loading: boolean;
    error: string | null;
}
const initialState: DataReviewUlasanState = {
    dataReviewUlasan: [],
    loading: false,
    error: null,
};
export const constructQuery = (params: { tab: string }) => {
    const { tab } = params;
    let query = '';
    if (tab) query += `${tab}`;
    return query;
};
export const getReviewListVendor = createAsyncThunk<ReviewUlasan[], string>(
    'ReviewUlasan/getReviewListVendor',
    async (query, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get(`/review-vendor/list${query}`);
            return response.data.data;
        } catch (error: any) {
            console.error('Error fetching getReviewListVendor:', error);
            return rejectWithValue(error.response?.data?.message || 'Failed to fetch review list');
        }
    },
);
export const replyReviewUlasan = createAsyncThunk<ReviewUlasan, { reviewID: string; komentar_reply: string }>(
    'ReviewUlasan/replyReviewUlasan',
    async ({ reviewID, komentar_reply }, { rejectWithValue }) => {
        try {
            const payload = { komentar_reply };
            const response = await axiosInstance.post(`/replay-produk/reply/${reviewID}`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            console.log('succesfully reply review');
            return response.data.data;
        } catch (error: any) {
            console.error('Error replyReviewUlasan:', error);
            return rejectWithValue(error.response?.data?.message || 'Gagal reply review ulasan');
        }
    },
);
const UlasanVendorSlice = createSlice({
    name: 'ulasanVendor',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getReviewListVendor.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getReviewListVendor.fulfilled, (state, action: PayloadAction<ReviewUlasan[]>) => {
                state.loading = false;
                state.dataReviewUlasan = action.payload;
            })
            .addCase(getReviewListVendor.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload || 'Failed to fetch review list';
                console.error('Error fetching detail:', action.payload);
            })
            .addCase(replyReviewUlasan.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(replyReviewUlasan.fulfilled, (state, action: PayloadAction<ReviewUlasan>) => {
                state.loading = false;
                state.dataReviewUlasan = [...state.dataReviewUlasan, action.payload];
            })
            .addCase(replyReviewUlasan.rejected, (state, action) => {
                state.loading = false;
                state.error = (action.payload as string) || 'Failed to update review';
                console.error('Error updating review:', action.payload);
            });
    },
});

export const UlasanVendorService = { action: UlasanVendorSlice.actions, getReviewListVendor, constructQuery };
const UlasanVendorReducer = UlasanVendorSlice.reducer;
export default UlasanVendorReducer;
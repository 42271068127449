import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axiosInstance from '../../../../../api/axiosInstance';
import { LoginInfo } from '../types/loginInfo';

interface LoginState {
    loginInfo: LoginInfo[];
    loading: boolean;
    error: string | null;
    isOpen: boolean;
}

const initialState: LoginState = {
    loginInfo: [],
    loading: false,
    error: null,
    isOpen: false,
};

export const fetchLoginInfo = createAsyncThunk<LoginInfo[], void>('LoginInfo/fetchLoginInfo', async () => {
    try {
        const response = await axiosInstance.get(`/user/login-activities`);
        return response.data.devices;
    } catch (error) {
        console.error('Error fetching list Login:', error);
        throw error;
    }
});

const LoginInfoSlice = createSlice({
    name: 'LoginInfo',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchLoginInfo.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchLoginInfo.fulfilled, (state, action: PayloadAction<LoginInfo[]>) => {
                state.loading = false;
                state.loginInfo = action.payload;
            })
            .addCase(fetchLoginInfo.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch list login';
                console.error('Error fetching list login:', action.error.message);
            });
    },
});

export const LoginInfoService = { action: LoginInfoSlice.actions, fetchLoginInfo };
const LoginInfoReducer = LoginInfoSlice.reducer;
export default LoginInfoReducer;

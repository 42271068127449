import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface userData {
    nama: string,
    namaBadanUsaha: string,
    nomorAktaPerusahaan: number,
    noTeleponKantor: number,
    registerAs: string,
    province: string,
    regency: string,
    district: string,
    village: string,
    code_pos: number,
    address_description: string,
    long_pin_alamat: number,
    lat_pin_alamat: number,
    nomorNpwpPerusahaan: number
    nik: number,
    noNpwp: number,
    npwpFile: File | null
    file_ktp: File | null
}
const initialState: userData = {
    nama: "",
    namaBadanUsaha: "",
    nomorAktaPerusahaan: 0,
    noTeleponKantor: 0,
    registerAs: "",
    province: "",
    regency: "",
    district: "",
    village: "",
    code_pos: 0,
    address_description: "",
    long_pin_alamat: 0,
    lat_pin_alamat: 0,
    nomorNpwpPerusahaan: 0,
    nik: 0,
    noNpwp: 0,
    npwpFile: null,
    file_ktp: null
};

const RegisterInstansiKonsumenReducer = createSlice({
    name: 'user',
    initialState,
    reducers: {
        // onUpdateFieldForm: (state, action: PayloadAction<{ name: keyof UserData; value: string | number }>) => {
        //     const { name, value } = action.payload;
        //     (state[name] as string | number) = value;
        // },
        setNama: (state, action: PayloadAction<string>) => {
            state.nama = action.payload;
        },
        setNamaBadanUsaha: (state, action: PayloadAction<string>) => {
            state.namaBadanUsaha = action.payload;
        },
        setNomorAktaPerusahaan: (state, action: PayloadAction<number>) => {
            state.nomorAktaPerusahaan = action.payload;
        },
        setNoTeleponKantor: (state, action: PayloadAction<number>) => {
            state.noTeleponKantor = action.payload;
        },
        setRegisterAs: (state, action: PayloadAction<string>) => {
            state.registerAs = action.payload;
        },
        setProvince: (state, action: PayloadAction<string>) => {
            state.province = action.payload;
        },
        setRegencyy: (state, action: PayloadAction<string>) => {
            state.regency = action.payload;
        },
        setDistrictt: (state, action: PayloadAction<string>) => {
            state.district = action.payload;
        },
        setVillage: (state, action: PayloadAction<string>) => {
            state.village = action.payload;
        },
        setCodePos: (state, action: PayloadAction<number>) => {
            state.code_pos = action.payload;
        },
        setAddressDescription: (state, action: PayloadAction<string>) => {
            state.address_description = action.payload;
        },
        setLong_pin_alamat: (state, action: PayloadAction<number>) => {
            state.long_pin_alamat = action.payload;
        },
        setLat_pin_alamat: (state, action: PayloadAction<number>) => {
            state.lat_pin_alamat = action.payload;
        },
        setNomorNpwpPerusahaan: (state, action: PayloadAction<number>) => {
            state.nomorNpwpPerusahaan = action.payload;
        },
        setNik: (state, action: PayloadAction<number>) => {
            state.nik = action.payload;
        },
        setnpwpFile: (state, action: PayloadAction<File | null>) => {
            state.npwpFile = action.payload;
        },
        setFile_ktp: (state, action: PayloadAction<File | null>) => {
            state.file_ktp = action.payload;
        },
        resetUserData: () => initialState,
    },
});

export const {
    setNama,
    setNamaBadanUsaha,
    setNomorAktaPerusahaan,
    setNoTeleponKantor,
    setRegisterAs,
    setProvince,
    setRegencyy,
    setDistrictt,
    setVillage,
    setCodePos,
    setAddressDescription,
    setLong_pin_alamat,
    setLat_pin_alamat,
    setNomorNpwpPerusahaan,
    setNik,
    setnpwpFile,
    setFile_ktp
} = RegisterInstansiKonsumenReducer.actions;

export default RegisterInstansiKonsumenReducer.reducer;


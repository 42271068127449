import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Cookies from 'universal-cookie';
import { Category, DataCategory } from '../../../../konsumen/helpers/interface/product/category';
import axiosInstance from '../../../../api/axiosInstance';

interface CategoryState {
    categories: Category[];
    loading: boolean;
    error: string | null;
}

const initialState: CategoryState = {
    categories: [],
    loading: false,
    error: null,
};
const cookies = new Cookies();

export const fetchCategories = createAsyncThunk<Category[], void, { rejectValue: string }>(
    'categories/fetchCategories',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get<DataCategory>(`${process.env.REACT_APP_API_URL}/category/list`);            
            return response.data.data;
        } catch (error: any) {
            console.log(error);
            const deviceId = cookies.get('device_id');
            const token = cookies.get('token');
            if (error.response && error.response.status === 401) {
                // Clear cookies if unauthorized
                // cookies.remove('token');
                // cookies.remove('device_id');
            }
            return rejectWithValue('Failed to fetch categories');
        }
    }
);

const categorySlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCategories.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCategories.fulfilled, (state, action) => {
                state.categories = action.payload;
                state.loading = false;
            })
            .addCase(fetchCategories.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to Fetch categories';
            });
    },
});

export default categorySlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addBioVendorState } from '../../interfaces';

const initialState: addBioVendorState = {
    selectedDate: null,
    selectedOption: 'Pilih jenis instansi',
    selectedGender: 'Pilih jenis kelamin',
    emailValue: '',
    phoneValue: '',
    uploadImageBio: [],
    uploadImageBioPreview: [],
    isBackground: false,
};

const AddBioVendorSlice = createSlice({
    name: 'addingBioVendor',
    initialState,
    reducers: {
        onSelectedOption: (state, action: PayloadAction<string>) => {
            state.selectedOption = action.payload;
        },
        onSelectedGender: (state, action: PayloadAction<string>) => {
            state.selectedGender = action.payload;
        },
        onSelectedDate: (state, action: PayloadAction<Date | null>) => {
            state.selectedDate = action.payload;
        },
        onTypingPhone: (state, action: PayloadAction<string>) => {
            state.phoneValue = action.payload;
        },
        onUploadImageBio: (state, action: PayloadAction<File[]>) => {
            state.uploadImageBio = action.payload;
        },
        onChangeBg: (state) => {
            state.isBackground = !state.isBackground;
        }
    },
});

export const AddBioVendorService = { action: AddBioVendorSlice.actions };
const AddBioVendorReducer = AddBioVendorSlice.reducer;
export default AddBioVendorReducer;

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ListAlamatVendor } from '../../../../../Vendor/types';
import axiosInstance from '../../../../../api/axiosInstance';

interface AlamatState {
    listAlamat: ListAlamatVendor[];
    loading: boolean;
    error: string | null;
    isOpen: boolean;
}

const initialState: AlamatState = {
    listAlamat: [],
    loading: false,
    error: null,
    isOpen: false,
};

export const fetchListAlamatVendor = createAsyncThunk<ListAlamatVendor[], void>(
    'ListAlamat/fetchListAlamatVendor',
    async () => {
        try {
            const response = await axiosInstance.get(`/address/list`);
            return response.data.datas;
        } catch (error) {
            console.error('Error fetching list alamat:', error);
            throw error;
        }
    },
);

export const createAlamatPenerimaVendor = createAsyncThunk<ListAlamatVendor,{ formData: FormData }>(
    'ListAlamat/createAlamatPenerimaVendor',
    async ({ formData }, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post(`/address/create`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('Successfully created address');
            return response.data.data;
        } catch (error: any) {
            console.error('Error creating address:', error);
            return rejectWithValue(error.response?.data?.message || 'Failed to create address');
        }
    },
);

export const updatedAlamatPenerimaVendor = createAsyncThunk<ListAlamatVendor,{ formData: FormData, updateID: string }>(
    'ListAlamat/updatedAlamatPenerimaVendor',
    async ({ formData, updateID }, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.put(`/address/update/${updateID}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('Successfully updating address');
            return response.data.data;
        } catch (error: any) {
            console.error('Error updating address:', error);
            return rejectWithValue(error.response?.data?.message || 'Failed to updating address');
        }
    },
);

const AlamatVendorSlice = createSlice({
    name: 'alamatVendor',
    initialState,
    reducers: {
        setIsOpenModalPopUp: (state, action: PayloadAction<boolean>) => {
            state.isOpen = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchListAlamatVendor.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchListAlamatVendor.fulfilled, (state, action: PayloadAction<ListAlamatVendor[]>) => {
                state.loading = false;
                state.listAlamat = action.payload;
            })
            .addCase(fetchListAlamatVendor.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch list alamat';
                console.error('Error fetching list alamat:', action.error.message);
            })
            .addCase(createAlamatPenerimaVendor.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createAlamatPenerimaVendor.fulfilled, (state, action: PayloadAction<ListAlamatVendor>) => {
                state.loading = false;
                state.listAlamat = [...state.listAlamat, action.payload];
            })
            .addCase(createAlamatPenerimaVendor.rejected, (state, action) => {
                state.loading = false;
                state.error = (action.payload as string) || 'Failed to update review';
                console.error('Error updating review:', action.payload);
            })
            .addCase(updatedAlamatPenerimaVendor.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updatedAlamatPenerimaVendor.fulfilled, (state, action: PayloadAction<ListAlamatVendor>) => {
                state.loading = false;
                state.listAlamat = [...state.listAlamat, action.payload];
            })
            .addCase(updatedAlamatPenerimaVendor.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string || 'Failed to update address';
                console.error('Error updating address:', state.error);
            });
    },
});

export const { setIsOpenModalPopUp } = AlamatVendorSlice.actions;
export const AlamatVendorService = { action: AlamatVendorSlice.actions, fetchListAlamatVendor };
const alamatVendorReducer = AlamatVendorSlice.reducer;
export default alamatVendorReducer;

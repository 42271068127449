import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../../../../api/axiosInstance';
import { PaymentCheck } from './interface';

export const GetOrderDetail = createAsyncThunk(
    'order/detail',
    async ({
        orderId,
    }: {
        orderId: string
    }) => {
        const response = await axiosInstance.get(`/order/check-payment?order_id=${orderId}`);
        return response.data;
    }
);

// Redux slice
const konsumenNotification = createSlice({
    name: 'orderDetail',
    initialState: {
        data: null as PaymentCheck | null,
        loading: false,
        error: null as string | null | undefined,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetOrderDetail.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(GetOrderDetail.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(GetOrderDetail.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});


export default konsumenNotification.reducer;

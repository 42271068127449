import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axiosInstance from '../../../../../api/axiosInstance';
import { ListAlamat } from '../types/alamatTypes';

interface AlamatState {
    listAlamat: ListAlamat[];
    loading: boolean;
    error: string | null;
    isOpen: boolean;
}

const initialState: AlamatState = {
    listAlamat: [],
    loading: false,
    error: null,
    isOpen: false,
};

export const fetchListAlamatUser = createAsyncThunk<ListAlamat[], void>('ListAlamat/fetchListAlamatUser', async () => {
    try {
        const response = await axiosInstance.get(`/address/list`);
        return response.data.datas;
    } catch (error) {
        console.error('Error fetching list alamat:', error);
        throw error;
    }
});

export const createAlamatUser = createAsyncThunk<ListAlamat, { formData: FormData }>(
    'ListAlamat/createAlamat',
    async ({ formData }, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post(`/address/create`, formData);
            console.log('Successfully created address');
            return response.data.data;
        } catch (error: any) {
            console.error('Error creating address:', error);
            return rejectWithValue(error.response?.data?.message || 'Failed to create address');
        }
    },
);

export const updatedAlamatUser = createAsyncThunk<ListAlamat, { formData: FormData; updateID: string }>(
    'ListAlamat/updatedAlamatUser',
    async ({ formData, updateID }, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.put(`/address/update/${updateID}`, formData);
            console.log('Successfully updating address');
            return response.data.data;
        } catch (error: any) {
            console.error('Error updating address:', error);
            return rejectWithValue(error.response?.data?.message || 'Failed to updating address');
        }
    },
);

const AlamatUserSlice = createSlice({
    name: 'alamatUser',
    initialState,
    reducers: {
        setIsOpenModalPopUp: (state, action: PayloadAction<boolean>) => {
            state.isOpen = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchListAlamatUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchListAlamatUser.fulfilled, (state, action: PayloadAction<ListAlamat[]>) => {
                state.loading = false;
                state.listAlamat = action.payload;
            })
            .addCase(fetchListAlamatUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch list alamat';
                console.error('Error fetching list alamat:', action.error.message);
            })
            .addCase(createAlamatUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createAlamatUser.fulfilled, (state, action: PayloadAction<ListAlamat>) => {
                state.loading = false;
                state.listAlamat = [...state.listAlamat, action.payload];
            })
            .addCase(createAlamatUser.rejected, (state, action) => {
                state.loading = false;
                state.error = (action.payload as string) || 'Failed to update review';
                console.error('Error updating review:', action.payload);
            })
            .addCase(updatedAlamatUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updatedAlamatUser.fulfilled, (state, action: PayloadAction<ListAlamat>) => {
                state.loading = false;
                state.listAlamat = [...state.listAlamat, action.payload];
            })
            .addCase(updatedAlamatUser.rejected, (state, action) => {
                state.loading = false;
                state.error = (action.payload as string) || 'Failed to update address';
                console.error('Error updating address:', state.error);
            });
    },
});

export const { setIsOpenModalPopUp } = AlamatUserSlice.actions;
export const AlamatUserService = { action: AlamatUserSlice.actions, fetchListAlamatUser };
const alamatUserReducer = AlamatUserSlice.reducer;
export default alamatUserReducer;

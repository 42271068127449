import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { dataAdmin, userInfo, dataToko } from './interface';

export const setDataToko = createAsyncThunk<dataToko, dataToko>(
    'UserInfo/setDataToko',
    async (dataToko, { rejectWithValue }) => {
        try {
            return dataToko;
        } catch (error) {
            if (error instanceof Error && 'response' in error && (error as any).response.data) {
                return rejectWithValue((error as any).response.data);
            }
            // Jika error tidak memiliki `response.data`, kembalikan pesan error umum
            return rejectWithValue("An unexpected error occurred");
        }
    }
);

const initialState: userInfo = {
    step: 1,
    id: '',
    registAs: '',
    dataAdmin: {
        nama: '',
        fullAddress: '',
        lat_pin_alamat: 0,
        long_pin_alamat: 0,
        code_pos: '',
        address_description: '',
        nik: 0,
        nomor_npwp: 0,
        file_ktp: null as unknown as File,
        npwpFile: null as unknown as File,
    },
    dataToko: {
        namaToko: '',
        fullAddress: '',
        code_pos: '',
        address_description: '',
        lat_pin_alamat: 0,
        long_pin_alamat: 0
    },
};

const ProdusenAuthRegistIndividual = createSlice({
    name: 'UserInfo',
    initialState,
    reducers: {
        setStep(state, action: PayloadAction<number>) {
            state.step = action.payload;
        },
        setId(state, action: PayloadAction<string>) {
            state.id = action.payload;
        },
        setRegisterAs(state, action: PayloadAction<string>) {
            state.registAs = action.payload;
        },
        setDataAdmin1(state, action: PayloadAction<Omit<dataAdmin, 'nik' | 'nomor_npwp' | 'file_ktp' | 'npwpFile'>>) {
            if (!state.dataAdmin) state.dataAdmin = {} as dataAdmin;
            state.dataAdmin = { ...state.dataAdmin, ...action.payload };
        },
        setDataAdmin2(state, action: PayloadAction<Pick<dataAdmin, 'nik' | 'nomor_npwp' | 'file_ktp' | 'npwpFile'>>) {
            if (!state.dataAdmin) state.dataAdmin = {} as dataAdmin;
            state.dataAdmin = { ...state.dataAdmin, ...action.payload };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(setDataToko.fulfilled, (state, action: PayloadAction<dataToko>) => {
                state.dataToko = { ...state.dataToko, ...action.payload };
            })
            .addCase(setDataToko.rejected, (state, action) => {
                console.error("Failed to set data toko:", action.payload);
            });
    },
});

export const { setStep, setId, setRegisterAs, setDataAdmin1, setDataAdmin2 } = ProdusenAuthRegistIndividual.actions;
export default ProdusenAuthRegistIndividual.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../../../../api/axiosInstance';
import { categoryListProps } from './interface';

export const getListCategory = createAsyncThunk(
  'categori/list',
  async () => { 
    const response = await axiosInstance.get(`/category/specific-category`); 
    return response.data;
  }
);

// Redux slice
const CategoryList = createSlice({
    name: 'categoryList',
    initialState: {
      data: null as categoryListProps | null,
      loading: false,
      error: null as string | null | undefined, 
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(getListCategory.pending, (state) => {
          state.loading = true;
          state.error = null;  
        })
        .addCase(getListCategory.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
        })
        .addCase(getListCategory.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;  
        });
    },
  });
  

export default CategoryList.reducer;

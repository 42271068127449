import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface nonSubsidiPayments {
    Price: number;
    isMaksimum: boolean;
  }
  

  
  const initialState: nonSubsidiPayments = {
    Price: 0,
    isMaksimum: false
  }

  const nonSubsidiPayment = createSlice({
    name: 'sekolah',
    initialState,
    reducers: {
      setPrice(state, action: PayloadAction<number>) {
        state.Price = action.payload;
      },
      setIsMaksimum(state, action: PayloadAction<boolean>) {
        state.isMaksimum = action.payload;
      },
    },
  });


export const { setPrice, setIsMaksimum } = nonSubsidiPayment.actions;
export default nonSubsidiPayment.reducer;
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../../../../../api/axiosInstance';
import { detailTransaksiProps, GetDetailTransactionParams } from './interface';

export const getDetailTransaction = createAsyncThunk(
    'transaction detail/list',
    async (params: GetDetailTransactionParams) => {
      const { orderId, status, sellerId, dateRange, paymentMethod } = params;
  
      const statusQuery =
        status === 'diproses' || status === 'dikirim' ? 'Berlangsung' : status;
  
      const url = `/order/detail/${orderId}?status_order=${statusQuery}&sellerId=${sellerId}` +
                  `${dateRange ? `&dateRange=${dateRange}` : ''}` +
                  `${paymentMethod ? `&paymentMethod=${paymentMethod}` : ''}`;
  
      const response = await axiosInstance.get(url);
      return response.data;
    }
  );

// Redux slice
const transactionDetail = createSlice({
    name: 'transaction-detail',
    initialState: {
      data: null as detailTransaksiProps | null,
      loading: false,
      error: null as string | null | undefined, 
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(getDetailTransaction.pending, (state) => {
          state.loading = true;
          state.error = null;  
        })
        .addCase(getDetailTransaction.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
        })
        .addCase(getDetailTransaction.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;  
        });
    },
  });
  

export default transactionDetail.reducer;

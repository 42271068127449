import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { KonsumenDetail } from '../../../../../konsumen/pages/home/interface';
import axiosInstance from '../../../../../api/axiosInstance';

interface ResponseDataState {
    data: KonsumenDetail | null;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
}

const initialState: ResponseDataState = {
    data: null,
    status: 'idle',
    error: null,
};

// Define the async thunk
export const getKonsumenDetail = createAsyncThunk(
    'responseData/getKonsumenDetail',
    async () => {
        try {
            const response = await axiosInstance.get('/konsumen/detail');
            return response.data;
        } catch (error) {
            console.error('Error fetching konsumen detail:', error);
            throw error;
        }
    }
);

const responseDataSlice = createSlice({
    name: 'responseData',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getKonsumenDetail.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(getKonsumenDetail.fulfilled, (state, action: PayloadAction<KonsumenDetail>) => {
                state.status = 'succeeded';
                state.data = action.payload;
            })
            .addCase(getKonsumenDetail.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            });
    }
});

export default responseDataSlice.reducer;

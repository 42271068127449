import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axiosInstance from '../../../../api/axiosInstance';
import { VerifUser } from '../../../../distributor/types';

interface UserVerifState {
    userVerif: VerifUser;
    loading: boolean;
    error: string | null;
}

const initialState: UserVerifState = {
    userVerif: {
        id: '',
        phone: 0,
    },
    loading: false,
    error: null,
};

export const verifUserNomor = createAsyncThunk<VerifUser, { formData: FormData }>(
    'VerifUser/verifUser',
    async ({ formData }) => {
        try {
            const response = await axiosInstance.post(`/user/verify-credentials`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error send otp:', error);
            throw error;
        }
    },
);

const UserVerifSlice = createSlice({
    name: 'verifUser',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(verifUserNomor.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(verifUserNomor.fulfilled, (state, action: PayloadAction<VerifUser>) => {
                state.loading = false;
                state.userVerif = action.payload;
            })
            .addCase(verifUserNomor.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to verif user';
                console.error('Error fetching verif:', action.error.message);
            });
    },
});

export const UserVerifService = { action: UserVerifSlice.actions, verifUserNomor };
const userVerifReducer = UserVerifSlice.reducer;
export default userVerifReducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../../../../api/axiosInstance';
import { StoreRekomendationProp } from '../../../../../konsumen/pages/profile/menu/daftarKeinginan/section/rekomendasi/interface';

export const GetStoreRekomendation = createAsyncThunk(
  'product/getDetailProduct',
  async () => { 
    const response = await axiosInstance.get(`/product/product_with_radius_konsumen?wishlist=false`);
    return response.data;
  }
);

// Redux slice
const StoreRekomendation = createSlice({
    name: 'StoreRekomendation',
    initialState: {
      data: null as StoreRekomendationProp | null,
      loading: false,
      error: null as string | null | undefined, 
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(GetStoreRekomendation.pending, (state) => {
          state.loading = true;
          state.error = null;  
        })
        .addCase(GetStoreRekomendation.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
        })
        .addCase(GetStoreRekomendation.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;  
        });
    },
  });
  

export default StoreRekomendation.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PinAlamat {
    lat: string;
    long: string;
  }
  
interface Kelas {
    namaKelas: string;
    jumlahMuridKelas: number;
  }
  interface addressDesk {
    address_description: string;
    code_pos: string;
    district: string;
    isMain: boolean;
    isPic: boolean;
    isSchool: boolean;
    isUsed: boolean;
    pinAlamat: PinAlamat;
    province: string;
    regency: string;
    userId: string;
    village: string;
    __v: number;
    _id: string;
  }
interface Sekolah {
    NPSN: string;
    address: addressDesk;
    detailId: string;
    jenisPendidikan: string;
    jenjangPendidikan: string;
    jumlahMurid: number;
    kelas: Kelas[];
    logoSekolah: string;
    namaSekolah: string;
    satuanPendidikan: string;
    statusSekolah: string;
    userId: number;
    __v: number;
    _id: string;
  }
  

interface SekolahState {
    list: Sekolah[];
  }
  
  const initialState: SekolahState = {
    list: [],
  };

  const sekolahSlice = createSlice({
    name: 'sekolah',
    initialState,
    reducers: {
      setSekolahList(state, action: PayloadAction<Sekolah[]>) {
        state.list = action.payload;
      },
      addSekolah(state, action: PayloadAction<Sekolah>) {
        state.list.push(action.payload);
      },
      removeSekolah(state, action: PayloadAction<string>) {
        state.list = state.list.filter(sekolah => sekolah._id !== action.payload);
      },
    },
  });


export const { setSekolahList, addSekolah, removeSekolah } = sekolahSlice.actions;
export default sekolahSlice.reducer;
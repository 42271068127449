import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../../../../api/axiosInstance';
import { addressDetailProps } from './interface';

export const getDetailAddress = createAsyncThunk(
  'address/list',
  async () => { 
    const response = await axiosInstance.get(`/address/list`); 
    return response.data;
  }
);

// Redux slice
const addressDetail = createSlice({
    name: 'detailAddress',
    initialState: {
      data: null as addressDetailProps | null,
      loading: false,
      error: null as string | null | undefined, 
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(getDetailAddress.pending, (state) => {
          state.loading = true;
          state.error = null;  
        })
        .addCase(getDetailAddress.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
        })
        .addCase(getDetailAddress.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;  
        });
    },
  });
  

export default addressDetail.reducer;

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DistributorDetail, RiwayatPengiriman } from '../../../../../distributor/types';
import axiosInstance from '../../../../../api/axiosInstance';
interface RiwayatPengirimanState {
    riwayatPengiriman: RiwayatPengiriman[];
    loading: boolean;
    error: string | null;
}

const initialState: RiwayatPengirimanState = {
    riwayatPengiriman: [],
    loading: false,
    error: null,
};

export const fetchRiwayatPengiriman = createAsyncThunk<RiwayatPengiriman[], void>(
    'RiwayatPengiriman/fetchRiwayatPengiriman',
    async () => {
        try {
            const response = await axiosInstance.get(`/pesanan-distributor/history`);
            return response.data.data;
        } catch (error) {
            console.error('Error fetching RiwayatPengiriman:', error);
            throw error;
        }
    },
);

const RiwayatPengirimanSlice = createSlice({
    name: 'riwayatPengiriman',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchRiwayatPengiriman.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchRiwayatPengiriman.fulfilled, (state, action: PayloadAction<RiwayatPengiriman[]>) => {
                state.loading = false;
                state.riwayatPengiriman = action.payload;
            })
            .addCase(fetchRiwayatPengiriman.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch detail distri';
                console.error('Error fetching detail:', action.error.message);
            });
    },
});

export const RiwayatPengirimanService = { action: RiwayatPengirimanSlice.actions, fetchRiwayatPengiriman };
const riwayatPengirimanReducer = RiwayatPengirimanSlice.reducer;
export default riwayatPengirimanReducer;

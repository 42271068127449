import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface userData {
    registerAs: string;
    nama: string;
    nama_distributor: string;
    nik: string;
    lat_pin_alamat: number;
    long_pin_alamat: number;
    province: string;
    regency: string;
    district: string;
    village: string;
    address_description: string;
    code_pos: string;
    nomorNpwp: string;
    npwpFile: File | null;
    file_ktp: File | null;
}
const initialState: userData = {
    registerAs: '',
    nama: '',
    nama_distributor: '',
    nik: '',
    lat_pin_alamat: 0,
    long_pin_alamat: 0,
    province: '',
    regency: '',
    district: '',
    village: '',
    address_description: '',
    code_pos: '',
    nomorNpwp: '',
    npwpFile: null,
    file_ktp: null,
};

const RegisterUserKonsumenReducer = createSlice({
    name: 'user',
    initialState,
    reducers: {
        // onUpdateFieldForm: (state, action: PayloadAction<{ name: keyof UserData; value: string | number }>) => {
        //     const { name, value } = action.payload;
        //     (state[name] as string | number) = value;
        // },
        setRegisterAs: (state, action: PayloadAction<string>) => {
            state.registerAs = action.payload;
        },
        setNama: (state, action: PayloadAction<string>) => {
            state.nama = action.payload;
        },
        setNik: (state, action: PayloadAction<string>) => {
            state.nik = action.payload;
        },
        setLatPinAlamat: (state, action: PayloadAction<number>) => {
            state.lat_pin_alamat = action.payload;
        },
        setLongPinAlamat: (state, action: PayloadAction<number>) => {
            state.long_pin_alamat = action.payload;
        },
        setProvince: (state, action: PayloadAction<string>) => {
            state.province = action.payload;
        },
        setRegencyy: (state, action: PayloadAction<string>) => {
            state.regency = action.payload;
        },
        setDistrictt: (state, action: PayloadAction<string>) => {
            state.district = action.payload;
        },
        setVillage: (state, action: PayloadAction<string>) => {
            state.village = action.payload;
        },
        setAddressDescription: (state, action: PayloadAction<string>) => {
            state.address_description = action.payload;
        },
        setCodePos: (state, action: PayloadAction<string>) => {
            state.code_pos = action.payload;
        },
        setNomorNpwp: (state, action: PayloadAction<string>) => {
            state.nomorNpwp = action.payload;
        },
        setnpwpFile: (state, action: PayloadAction<File | null>) => {
            state.npwpFile = action.payload;
        },
        setFile_ktp: (state, action: PayloadAction<File | null>) => {
            state.file_ktp = action.payload;
        },
        resetUserData: () => initialState,
    },
});

export const {
    // onUpdateFieldForm,
    setRegisterAs,
    setNama,
    setNik,
    setLatPinAlamat,
    setLongPinAlamat,
    setProvince,
    setRegencyy,
    setDistrictt,
    setVillage,
    setAddressDescription,
    setCodePos,
    setNomorNpwp,
    setnpwpFile,
    setFile_ktp,
    resetUserData,
} = RegisterUserKonsumenReducer.actions;

export default RegisterUserKonsumenReducer.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DistributorState {
    productId: string | null;
    userId: string | null;
}

const initialState: DistributorState = {
    productId: '',
    userId: '',
};

const distributorSlice = createSlice({
    name: 'distributor',
    initialState,
    reducers: {
        setProductId(state, action:PayloadAction<string>) {
            state.productId = action.payload;
          }      
    },
});

export const { setProductId } = distributorSlice.actions;
export default distributorSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PesananVendorState {
  countOrderan: number;
};

const initialState: PesananVendorState = {
    countOrderan: 0,
};

const PesananVendorSlice = createSlice({
    name: 'pesananVendor',
    initialState,
    reducers: {
        newOrderLength: (state, action: PayloadAction<number>) => {
          state.countOrderan = action.payload;
        }
    },
});

export const PesananVendorService = { action: PesananVendorSlice.actions };
const PesananVendorReducer = PesananVendorSlice.reducer;
export default PesananVendorReducer;
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductVendorState } from '../../interfaces';
import { ResponsePangan, PanganState, KategoriPanganState } from '../../../../Vendor/types';

const initialState: ProductVendorState = {
    selectVarian: false,
    judulProduct: '',
    kategoriUtama: '',
    kategoriKedua: '',
    kategoriKetiga: '',
    selectedFiles: [],
    previews: [],
    shortDescription: '',
    longDescription: '',
    longProduct: 0,
    widthProduct: 0,
    heightProduct: 0,
    productWeight: 0,
    minimalOrderan: 0,
    hargaProduct: 0,
    hargaDiscount: 0,
    minimalPembayaran: 0,
    stockProduct: 0,
    mainCategoryValue: '',
    detailCategoryValue: '',
    subCategoryValue: '',
    idDataCategory: '',
    valueKaloriMentahNotVarian: 0,
    getValueKaloriNotVarian: {},
    getPanganValueNotVarian: {
        nama: '',
        deskripsi: '',
        icon: '',
    },
    kelompokPanganNotVarian: {
        pict: null,
        alt: '',
        nama: '',
        desc: '',
        value: '',
    },
    dataPanganOlahNotVarian: {
        air: { value: { $numberDecimal: '0' }, simbol: '', deskripsi: '' },
        energi: { value: { $numberDecimal: '0' }, deskripsi: '' },
        protein: { value: { $numberDecimal: '0' }, simbol: '', deskripsi: '' },
        lemak: { value: { $numberDecimal: '0' }, simbol: '', deskripsi: '' },
        kh: { value: { $numberDecimal: '0' }, simbol: '', deskripsi: '' },
        serat: { value: { $numberDecimal: '0' }, simbol: '', deskripsi: '' },
        kalsium: { value: { $numberDecimal: '0' }, simbol: '', deskripsi: '' },
        fosfor: { value: { $numberDecimal: '0' }, simbol: '', deskripsi: '' },
        besi: { value: { $numberDecimal: '0' }, simbol: '', deskripsi: '' },
        natrium: { value: { $numberDecimal: '0' }, simbol: '', deskripsi: '' },
        kalium: { value: { $numberDecimal: '0' }, simbol: '', deskripsi: '' },
        tembaga: { value: { $numberDecimal: '0' }, simbol: '', deskripsi: '' },
        thiamin: { value: { $numberDecimal: '0' }, simbol: '', deskripsi: '' },
        riboflavin: { value: { $numberDecimal: '0' }, simbol: '', deskripsi: '' },
        vitc: { value: { $numberDecimal: '0' }, simbol: '', deskripsi: '' },
        _id: '',
        kode_bahan: '',
        nama_bahan: '',
        kelompok_pangan: '',
        jenis_pangan: '',
        nama_makanan_lokal: null,
        mayoritas_daerah_lokal: null,
        keterangan: null,
        __v: 0,
    },
};

const AddProductSlice = createSlice({
    name: 'productNotVarian',
    initialState,
    reducers: {
        onUpdateFieldForm: (state, action: PayloadAction<{ name: keyof ProductVendorState; value: any }>) => {
            const { name, value } = action.payload;
            (state[name] as string | number) = value;
            // (state[name] as any) = value;
        },
        setLongProduct: (state, action: PayloadAction<number>) => {
            state.longProduct = action.payload;
        },
        setWidthProduct: (state, action: PayloadAction<number>) => {
            state.widthProduct = action.payload;
        },
        setHeightProduct: (state, action: PayloadAction<number>) => {
            state.heightProduct = action.payload;
        },
        setWeightProduct: (state, action: PayloadAction<number>) => {
            state.productWeight = action.payload;
        },
        setMinimalOrder: (state, action: PayloadAction<number>) => {
            state.minimalOrderan = action.payload;
        },
        setPriceProduct: (state, action: PayloadAction<number>) => {
            state.hargaProduct = action.payload;
        },
        setPriceDiscount: (state, action: PayloadAction<number>) => {
            state.hargaDiscount = action.payload;
        },
        setMinPembayaran: (state, action: PayloadAction<number>) => {
            state.minimalPembayaran = action.payload;
        },
        setStockProduct: (state, action: PayloadAction<number>) => {
            state.stockProduct = action.payload;
        },
        setSelectedFiles: (state, action: PayloadAction<File[]>) => {
            state.selectedFiles = action.payload;
        },
        setPreviews: (state, action: PayloadAction<string[]>) => {
            state.previews = action.payload;
        },
        onGetMainValue: (state, action: PayloadAction<string>) => {
            state.mainCategoryValue = action.payload;
        },
        onGetDetailValue: (state, action: PayloadAction<string>) => {
            state.detailCategoryValue = action.payload;
        },
        onGetSubValue: (state, action: PayloadAction<string>) => {
            state.subCategoryValue = action.payload;
        },
        onGetIdCategory: (state, action: PayloadAction<string>) => {
            state.idDataCategory = action.payload;
        },
        onGetDataPanganOlahNV: (state, action: PayloadAction<ResponsePangan>) => {
            state.dataPanganOlahNotVarian = action.payload;
        },
        onGetValueKaloriMentahNV: (state, action: PayloadAction<number>) => {
            state.valueKaloriMentahNotVarian = action.payload;
        },
        onGetKelompokPanganNV: (state, action: PayloadAction<KategoriPanganState>) => {
            state.kelompokPanganNotVarian = action.payload;
        },
        setGetValueKaloriNotVarian: (state, action: PayloadAction<{ key: string; value: number }>) => {
            const { key, value } = action.payload;
            state.getValueKaloriNotVarian[key] = value;
        },
        onGetPanganValue: (state, action: PayloadAction<PanganState>) => {
            state.getPanganValueNotVarian = action.payload;
        },
        onClearFields: (state, action: PayloadAction<{ name: keyof ProductVendorState }>) => {
            const { name } = action.payload;
            if (isKeyofProductNotVarianVendor(name)) {
                if (typeof state[name] === 'string') {
                    (state[name] as string) = '';
                } else if (typeof state[name] === 'number') {
                    (state[name] as number) = 0;
                } else if (Array.isArray(state[name])) {
                    (state[name] as unknown as any[]) = [];
                }
            }
        },
    },
});

export const AddProductService = { action: AddProductSlice.actions };
const AddProductReducer = AddProductSlice.reducer;
export default AddProductReducer;

export const isKeyofProductNotVarianVendor = (key: any): key is keyof ProductVendorState => {
    return [
        'judulProduct',
        'kategoriUtama',
        'kategoriKedua',
        'kategoriKetiga',
        'selectedFiles',
        'previews',
        'shortDescription',
        'longDescription',
        'longProduct',
        'widthProduct',
        'heightProduct',
        'productWeight',
        'minimalOrderan',
        'hargaProduct',
        'hargaDiscount',
        'minimalPembayaran',
        'stockProduct',
    ].includes(key);
};
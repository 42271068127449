import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { paymentMethodProps } from './interface';
import axiosInstance from '../../../../api/axiosInstance';
interface ResponseData {
    data: paymentMethodProps | null;
    loading: boolean;
    error: string | null;
}
export const getPaymentMethod = createAsyncThunk(
    'payment/list',
    async () => { 
      const response = await axiosInstance.get(`/metode_pembayaran`); 
      return response.data;
    }
  );
const initialState: ResponseData = {
    data: null,
    loading: false,
    error: null,
};

const methodsSlice = createSlice({
    name: 'methods',
    initialState,
    reducers: {
        setMethods(state, action: PayloadAction<paymentMethodProps>) {
            state.data = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
          .addCase(getPaymentMethod.pending, (state) => {
            state.loading = true;
            state.error = null;  
          })
          .addCase(getPaymentMethod.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
          })
          .addCase(getPaymentMethod.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message || 'Gagal Menampilkan Data'; 
          });
      },
});

export const { setMethods } = methodsSlice.actions;
export default methodsSlice.reducer;
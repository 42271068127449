import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';

export interface FormsRoleState {
    activeTab: string;
    hidden: boolean;
    isShow: boolean;
    moveFormCompany: string;
    isOpenModal: boolean;
    isReset: boolean;
    selectedIdProvinsi: string;
    selectedIdKota: string;
    selectedIdKecamatan: string;
    selectedIdKelurahan: string;
    provinsiName: string;
    kotaName: string;
    kecamatanName: string;
    kelurahanName: string;
    openModalKota: boolean;
    openModalKecamatan: boolean;
    openModalKelurahan: boolean;
}

const initialState: FormsRoleState = {
    activeTab: 'Perusahaan',
    hidden: true,
    isShow: false,
    moveFormCompany: 'Data Perusahaan',
    isOpenModal: false,
    isReset: false,
    selectedIdProvinsi: '',
    selectedIdKota: '',
    selectedIdKecamatan: '',
    selectedIdKelurahan: '',
    provinsiName: '',
    kotaName: '',
    kecamatanName: '',
    kelurahanName: '',
    openModalKota: false,
    openModalKecamatan: false,
    openModalKelurahan: false,
};

const FormsSlice = createSlice({
    name: 'FormsRoleSlice',
    initialState,
    reducers: {
        setActiveTab: (state, action: PayloadAction<string>) => {
            state.activeTab = action.payload;
            state.hidden = action.payload !== 'Perusahaan';
        },
        setMoveFormCompany: (state, action: PayloadAction<string>) => {
            state.moveFormCompany = action.payload;
        },
        setIsOpenModal: (state, action: PayloadAction<boolean>) => {
            state.isOpenModal = action.payload;
        },
        setOpenModalKota: (state) => {
            state.openModalKota = !state.openModalKota;
        },
        setOpenModalKecamatan: (state, action: PayloadAction<boolean>) => {
            state.openModalKecamatan = action.payload;
        },
        setOpenModalKelurahan: (state) => {
            state.openModalKelurahan = false;
        },
        onGetIdProvince: (state, action: PayloadAction<string>) => {
            state.selectedIdProvinsi = action.payload;
            state.openModalKota = true;
            state.isOpenModal = false;
        },
        onGetIdCities: (state, action: PayloadAction<string>) => {
            state.selectedIdKota = action.payload;
            state.openModalKecamatan = true;
            state.openModalKota = false;
        },
        onGetIdSubdistrict: (state, action: PayloadAction<string>) => {
            state.selectedIdKecamatan = action.payload;
            state.openModalKelurahan = true;
            state.openModalKecamatan = false;
        },
        onGetIdUrbanVillage: (state, action: PayloadAction<string>) => {
            state.selectedIdKelurahan = action.payload;
            state.openModalKelurahan = true;
            state.openModalKecamatan = false;
        },
        onSelectNameProvinsi: (state, action: PayloadAction<string>) => {
            state.provinsiName = action.payload;
        },
        onSelectNameKota: (state, action: PayloadAction<string>) => {
            state.kotaName = action.payload;
        },
        onSelectNameKecamatan: (state, action: PayloadAction<string>) => {
            state.kecamatanName = action.payload;
        },
        onSelectNameKelurahan: (state, action: PayloadAction<string>) => {
            state.kelurahanName = action.payload;
        },
        setIsShow: (state, action: PayloadAction<boolean>) => {
            state.isShow = action.payload;
        },
        onResetIdProvinsi: (state) => {
            state.selectedIdProvinsi = initialState.selectedIdProvinsi;
        },
        onResetIdKota: (state) => {
            state.selectedIdKota = initialState.selectedIdKota;
        },
        onResetIdKecamatan: (state) => {
            state.selectedIdKecamatan = initialState.selectedIdKecamatan;
        },
        onHandleReset: (state) => {
            state.provinsiName = initialState.provinsiName;
            state.kotaName = initialState.kotaName;
            state.kecamatanName = initialState.kecamatanName;
            state.kelurahanName = initialState.kelurahanName;
        },
    },
});

export const FormsRoleSliceServis = { actions: FormsSlice.actions };
const RoleReducer = FormsSlice.reducer;
export default RoleReducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DellAccountReasonState {
    reason: string;
    id: string
}

const initialState: DellAccountReasonState = {
    reason: '',
    id: ''
};
const DellAccountReason = createSlice({
    name: 'DellAccountReason',
    initialState,
    reducers: {
        setReason: (state, action: PayloadAction<string>) => {
            state.reason = action.payload;
        },
        setId: (state, action: PayloadAction<string>) => {
            state.id = action.payload;
        },
    },
});
export const { setReason, setId } = DellAccountReason.actions;



export default DellAccountReason.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../../../../../api/axiosInstance';
import { recurringProps } from './interface';

export const getRecurringData = createAsyncThunk(
  'recurring/list',
  async () => { 
    const response = await axiosInstance.get(`/order/list`); 
    return response.data;
  }
);

// Redux slice
const productInRadius = createSlice({
    name: 'transaction-recurring',
    initialState: {
      data: null as recurringProps | null,
      loading: false,
      error: null as string | null | undefined, 
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(getRecurringData.pending, (state) => {
          state.loading = true;
          state.error = null;  
        })
        .addCase(getRecurringData.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
        })
        .addCase(getRecurringData.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;  
        });
    },
  });
  

export default productInRadius.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RegisterDistributorState } from '../../interfaces/Distributor.interface';

const initialState: RegisterDistributorState = {
    emailDistributor: '',
    phoneDistributor: '',
    selectRolesDistributor: '',
    passwordDistributor: '',
    idOtpDistributor: '',
    idPhone: '',
    phoneOTP: '',
    emailOTP: '',
    idEmail: '',
};

const RegisterDistributorSlice = createSlice({
    name: 'registerDistributor',
    initialState,
    reducers: {
        onChangeFields: (state, action: PayloadAction<{ name: keyof RegisterDistributorState; value: string }>) => {
            const { name, value } = action.payload;
            (state[name] as string) = value;
        },
        getEmailDistributor: (state, action: PayloadAction<string>) => {
            state.emailDistributor = action.payload;
        },
        getPhoneDistributor: (state, action: PayloadAction<string>) => {
            state.phoneDistributor = action.payload;
        },
        setRoleDistributor: (state, action: PayloadAction<string>) => {
            state.selectRolesDistributor = action.payload;
        },
        setPasswordDistributor: (state, action: PayloadAction<string>) => {
            state.passwordDistributor = action.payload;
        },
        getIdOtpDistributor: (state, action: PayloadAction<string>) => {
            state.idOtpDistributor = action.payload;
        },
        getIdPhone: (state, action: PayloadAction<string>) => {
            state.idPhone = action.payload;
        },
        getIdEmail: (state, action: PayloadAction<string>) => {
            state.idEmail = action.payload;
        },
        getEmailOTP: (state, action: PayloadAction<string>) => {
            state.emailOTP = action.payload;
        },
        getPhoneOTP: (state, action: PayloadAction<string>) => {
            state.phoneOTP = action.payload;
        },
    },
});

export const RegisterDistributorService = { action: RegisterDistributorSlice.actions };
const RegisterDistributorReducer = RegisterDistributorSlice.reducer;
export default RegisterDistributorReducer;
export const isKeyofRegisterDistributor = (key: any): key is keyof RegisterDistributorState => {
    return ['emailDistributor', 'phoneDistributor'].includes(key);
};

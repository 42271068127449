import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';

interface AlamatPerusahaanState {
    isOpenModalKonsumen: boolean;
    namaProvinsiKonsumen: string;
    namaKotaKonsumen: string;
    namaKecamatanKonsumen: string;
    namaKelurahanKonsumen: string;
    selectedIdProvinceKonsumen: string;
    selectedIdCityKonsumen: string;
    selectedIdSubdistrictKonsumen: string;
    selectedIdUrVillageKonsumen: string;
}

const initialState: AlamatPerusahaanState = {
    isOpenModalKonsumen: false,
    namaProvinsiKonsumen: '',
    namaKotaKonsumen: '',
    namaKecamatanKonsumen: '',
    namaKelurahanKonsumen: '',
    selectedIdProvinceKonsumen: '',
    selectedIdCityKonsumen: '',
    selectedIdSubdistrictKonsumen: '',
    selectedIdUrVillageKonsumen: '',
};

const AlamatPerusahaanSlice = createSlice({
    name: 'PerusahaanKonsumen',
    initialState,
    reducers: {
        setDisplayModalAlamatKonsumen: (state, action: PayloadAction<boolean>) => {
            state.isOpenModalKonsumen = action.payload;
        },
        getNameProvinceAlamatKonsumen: (state, action: PayloadAction<string>) => {
            state.namaProvinsiKonsumen = action.payload;
        },
        getNameCityAlamatKonsumen: (state, action: PayloadAction<string>) => {
            state.namaKotaKonsumen = action.payload;
        },
        getNameSubdistrictAlamatKonsumen: (state, action: PayloadAction<string>) => {
            state.namaKecamatanKonsumen = action.payload;
        },
        getNameUrVillageAlamatKonsumen: (state, action: PayloadAction<string>) => {
            state.namaKelurahanKonsumen = action.payload;
        },
        onResetAlamatKonsumen: (state) => {
            state.namaProvinsiKonsumen = initialState.namaProvinsiKonsumen;
            state.namaKotaKonsumen = initialState.namaKotaKonsumen;
            state.namaKecamatanKonsumen = initialState.namaKecamatanKonsumen;
            state.namaKelurahanKonsumen = initialState.namaKelurahanKonsumen;
        },
        getIdProvinceAlamatKonsumen: (state, action: PayloadAction<string>) => {
            state.selectedIdProvinceKonsumen = action.payload;
        },
        getIdCitiesAlamatKonsumen: (state, action: PayloadAction<string>) => {
            state.selectedIdCityKonsumen = action.payload;
        },
        getIdSubdistrictAlamatKonsumen: (state, action: PayloadAction<string>) => {
            state.selectedIdSubdistrictKonsumen = action.payload;
        },
        getIdUrVillageAlamatKonsumen: (state, action: PayloadAction<string>) => {
            state.selectedIdUrVillageKonsumen = action.payload;
        },

    },
});

export const AlamatPerusahaanService = { action: AlamatPerusahaanSlice.actions };
const AlamatPerusahaanReducer = AlamatPerusahaanSlice.reducer;
export default AlamatPerusahaanReducer;
import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';

export interface RoleState {
    role: string;
    passwordUser: string;
    idOtp: string;
}

const initialState: RoleState = {
    role: '',
    passwordUser: '',
    idOtp: '',
};

const RoleSlice = createSlice({
    name: 'RoleSlice',
    initialState,
    reducers: {
        setRole: (state, action: PayloadAction<string>) => {
            state.role = action.payload;
        },
        setPasswordUser: (state, action: PayloadAction<string>) => {
            state.passwordUser = action.payload;
        },
        setIdOtp: (state, action: PayloadAction<string>) => {
            state.idOtp = action.payload;
        },
    },
});

export const RoleSliceServis = { actions: RoleSlice.actions };
const RoleReducer = RoleSlice.reducer;
export default RoleReducer;

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnalisisLayanan, DashboardDistri, GrafikPerforma } from '../../../../../distributor/types';
import axiosInstance from '../../../../../api/axiosInstance';

interface AnalisisState {
    listRataRataLayanan: AnalisisLayanan[];
    grafikPerforma: GrafikPerforma[];
    dashboard: DashboardDistri;
    loading: boolean;
    error: string | null;
}

const initialState: AnalisisState = {
    dashboard: {
        totalProduk: 0,
        rata_rata_pengiriman: 0,
        perfoma_pesanan: 0,
        pembatalan_pesanan: 0,
    },
    grafikPerforma: [],
    listRataRataLayanan: [],
    loading: false,
    error: null,
};

export const fetchListLayanan = createAsyncThunk<AnalisisLayanan[], void>(
    'AnalisisLayanan/fetchListLayanan',
    async () => {
        try {
            const response = await axiosInstance.get(`/dashboard-distributor/rata-rata-layanan`);
            return response.data.rata_rata_pengiriman;
        } catch (error) {
            console.error('Error fetching list layanan:', error);
            throw error;
        }
    },
);
export const fetchGrafikPerforma = createAsyncThunk<GrafikPerforma[], void>(
    'AnalisisLayanan/fetchGrafikPerforma',
    async () => {
        try {
            const response = await axiosInstance.get(`/dashboard-distributor/grafik-performa`);
            return response.data.data;
        } catch (error) {
            console.error('Error fetching grafik performa:', error);
            throw error;
        }
    },
);
export const fetchDashboardDistri = createAsyncThunk<DashboardDistri, void>(
    'AnalisisLayanan/fetchDashboardDistri',
    async () => {
        try {
            const response = await axiosInstance.get(`/dashboard-distributor/dashboard`);
            return response.data;
        } catch (error) {
            console.error('Error fetching dashboard:', error);
            throw error;
        }
    },
);

const AnalisisLayananSlice = createSlice({
    name: 'analisisLayanan',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchListLayanan.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchListLayanan.fulfilled, (state, action: PayloadAction<AnalisisLayanan[]>) => {
                state.loading = false;
                state.listRataRataLayanan = action.payload;
            })
            .addCase(fetchListLayanan.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch list layanan';
                console.error('Error fetching layanan list:', action.error.message);
            })
            .addCase(fetchGrafikPerforma.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchGrafikPerforma.fulfilled, (state, action: PayloadAction<GrafikPerforma[]>) => {
                state.loading = false;
                state.grafikPerforma = action.payload;
            })
            .addCase(fetchGrafikPerforma.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch grafik performa';
                console.error('Error fetching grafik performa:', action.error.message);
            })
            .addCase(fetchDashboardDistri.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchDashboardDistri.fulfilled, (state, action: PayloadAction<DashboardDistri>) => {
                state.loading = false;
                state.dashboard = action.payload;
            })
            .addCase(fetchDashboardDistri.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch dashboard';
                console.error('Error fetching dashboard:', action.error.message);
            });
    },
});

export const AnalisisLayananService = { action: AnalisisLayananSlice.actions, fetchListLayanan };
const analisisLayananReducer = AnalisisLayananSlice.reducer;
export default analisisLayananReducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PutPassword } from '../../../../../konsumen/pages/login/formLogin/section/forgotPassword/interface';


const initialState: PutPassword = {
  message: '',
  token: '',
  id: '',
  email: ''
};

const dataPutPassword = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    setMail(state, action: PayloadAction<string>) {
      state.email = action.payload;
    },
    setUserInfo(state, action: PayloadAction<{ message: string; token: string; id: string }>) {
        state.message = action.payload.message;
        state.token = action.payload.token;
        state.id = action.payload.id;
      },
  },
});

export const { setUserInfo, setMail } = dataPutPassword.actions;

export default dataPutPassword.reducer;

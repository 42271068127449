import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';

export interface FormValueIndividual {
    fullname: string;
    address: string;
    detailAddress: string;
    postalCode: string;
    nik: string;
    imageKTP: File | null;
    npwp: string;
    imageNPWP: File | null;
}

const initialState: FormValueIndividual = {
    fullname: '',
    address: '',
    detailAddress: '',
    postalCode: '',
    nik: '',
    imageKTP: null,
    npwp: '',
    imageNPWP: null,
};

const FormsIndividualSlice = createSlice({
    name: 'form',
    initialState,
    reducers: {
        updateField: (state, action: PayloadAction<{ name: keyof FormValueIndividual; value: any }>) => {
            state[action.payload.name] = action.payload.value;
        },
        updateFieldTextarea: (state, action: PayloadAction<{ name: keyof FormValueIndividual; value: any }>) => {
            state[action.payload.name] = action.payload.value;
        },
        submitForm: (state, action: PayloadAction<FormValueIndividual>) => {
            console.log('submitted submit form', action.payload);
        },
    },
});

export const FormsIndividualSliceService = { actions: FormsIndividualSlice.actions };
const FormsIndividualReducer = FormsIndividualSlice.reducer;
export default FormsIndividualReducer;

export const isKeyofFormValueIndividual = (key: any): key is keyof FormValueIndividual => {
    return ['fullname', 'address', 'detailAddress', 'postalCode', 'nik', 'npwp', 'imageKTP', 'imageNPWP'].includes(key);
};

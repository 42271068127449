import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ContactInfoState {
  email: string;
  phone: string;
}

const initialState: ContactInfoState = {
  email: '',
  phone: '',
};

const contactInfoSlice = createSlice({
  name: 'contactInfo',
  initialState,
  reducers: {
    updateEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    updatePhone: (state, action: PayloadAction<string>) => {
      state.phone = action.payload;
    },
    resetContactInfo: (state) => {
      state.email = '';
      state.phone = '';
    },
  },
});

export const { updateEmail, updatePhone, resetContactInfo } = contactInfoSlice.actions;
export default contactInfoSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface userData {
    id: string,
    role: string
    email: string
}
const initialState: userData = {
    id: "",
    role: "",
    email: ''
};

const RegisterUserReducer = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload;
        },
        setIdUser: (state, action: PayloadAction<string>) => {
            state.id = action.payload;
        },
        setRole: (state, action: PayloadAction<string>) => {
            state.role = action.payload;
        },
        resetUserData: () => initialState,
    },
});

export const {
    // onUpdateFieldForm,
    setEmail,
    setIdUser,
    setRole,
    resetUserData,
} = RegisterUserReducer.actions;

export default RegisterUserReducer.reducer;


import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductVarianState } from '../../interfaces';
import { ResponsePangan, PanganState, KategoriPanganState, KategoriPanganVarianState } from '../../../../Vendor/types';

const initialState: ProductVarianState = {
    selectVarian: true,
    nameProduct: '',
    categoryMain: '',
    categorySecond: '',
    categoryThird: '',
    dataFiles: [],
    dataPreviews: [],
    shortDescription: '',
    longDescription: '',
    priceProduct: 0,
    priceDiscount: 0,
    minBuying: 0,
    minOrderan: 0,
    productStock: 0,
    panjangProduct: 0,
    lebarProduct: 0,
    tinggiProduct: 0,
    beratProduct: 0,
    nameVarian: '',
    valueVarian: '',
    priceVarian: 0,
    getIdDataCategory: '',
    getPanganValue: {
        nama: '',
        deskripsi: '',
        icon: '',
    },
    kelompokPangan: {
        pict: null,
        alt: '',
        nama: '',
        desc: '',
        value: '',
    },
    panganMentahVarian: {
        pict: null,
        alt: '',
        nama: '',
        desc: '',
        value: '',
    },
    dataPanganOlah: {
        air: { value: { $numberDecimal: '0' }, simbol: '', deskripsi: '' },
        energi: { value: { $numberDecimal: '0' }, deskripsi: '' },
        protein: { value: { $numberDecimal: '0' }, simbol: '', deskripsi: '' },
        lemak: { value: { $numberDecimal: '0' }, simbol: '', deskripsi: '' },
        kh: { value: { $numberDecimal: '0' }, simbol: '', deskripsi: '' },
        serat: { value: { $numberDecimal: '0' }, simbol: '', deskripsi: '' },
        kalsium: { value: { $numberDecimal: '0' }, simbol: '', deskripsi: '' },
        fosfor: { value: { $numberDecimal: '0' }, simbol: '', deskripsi: '' },
        besi: { value: { $numberDecimal: '0' }, simbol: '', deskripsi: '' },
        natrium: { value: { $numberDecimal: '0' }, simbol: '', deskripsi: '' },
        kalium: { value: { $numberDecimal: '0' }, simbol: '', deskripsi: '' },
        tembaga: { value: { $numberDecimal: '0' }, simbol: '', deskripsi: '' },
        thiamin: { value: { $numberDecimal: '0' }, simbol: '', deskripsi: '' },
        riboflavin: { value: { $numberDecimal: '0' }, simbol: '', deskripsi: '' },
        vitc: { value: { $numberDecimal: '0' }, simbol: '', deskripsi: '' },
        _id: '',
        kode_bahan: '',
        nama_bahan: '',
        kelompok_pangan: '',
        jenis_pangan: '',
        nama_makanan_lokal: null,
        mayoritas_daerah_lokal: null,
        keterangan: null,
        __v: 0,
    },
    getValueKaloriMentah: '',
    getKalMentah: '',
    getKalMentahProduct: '',
    getValueKategori: '',
    section: 'pangan mentah varian',
    isOpenSuccesEditProduct: false,
    getNameUpdateProduct: '',
};

const AddProductVarianSlice = createSlice({
    name: 'productVarian',
    initialState,
    reducers: {
        onUpdateFieldVarian: (
            state,
            action: PayloadAction<{ name: keyof ProductVarianState; value: string | number }>,
        ) => {
            const { name, value } = action.payload;
            (state[name] as string | number) = value;
        },
        setPanjangProduct: (state, action: PayloadAction<number>) => {
            state.panjangProduct = action.payload;
        },
        setLebarProduct: (state, action: PayloadAction<number>) => {
            state.lebarProduct = action.payload;
        },
        setTinggiProduct: (state, action: PayloadAction<number>) => {
            state.tinggiProduct = action.payload;
        },
        setBeratProduct: (state, action: PayloadAction<number>) => {
            state.beratProduct = action.payload;
        },
        setMinimalOrderan: (state, action: PayloadAction<number>) => {
            state.minOrderan = action.payload;
        },
        setPriceDiscount: (state, action: PayloadAction<number>) => {
            state.priceDiscount = action.payload;
        },
        setStockProduct: (state, action: PayloadAction<number>) => {
            state.productStock = action.payload;
        },
        setPriceProduct: (state, action: PayloadAction<number>) => {
            state.priceProduct = action.payload;
        },
        setMinimalBuying: (state, action: PayloadAction<number>) => {
            state.minBuying = action.payload;
        },
        setDataFiles: (state, action: PayloadAction<File[]>) => {
            state.dataFiles = action.payload;
        },
        setDataPreviews: (state, action: PayloadAction<string[]>) => {
            state.dataPreviews = action.payload;
        },
        onGetValuedMainCategory: (state, action: PayloadAction<string>) => {
            state.categoryMain = action.payload;
        },
        onGetValuedSecondCategory: (state, action: PayloadAction<string>) => {
            state.categorySecond = action.payload;
        },
        onGetValuedThirdCategory: (state, action: PayloadAction<string>) => {
            state.categoryThird = action.payload;
        },
        onGetIdLastCategory: (state, action: PayloadAction<string>) => {
            state.getIdDataCategory = action.payload;
        },
        getDataPanganOlah: (state, action: PayloadAction<ResponsePangan>) => {
            state.dataPanganOlah = action.payload;
        },
        onGetPanganValue: (state, action: PayloadAction<PanganState>) => {
            state.getPanganValue = action.payload;
        },
        onGetKelompokPangan: (state, action: PayloadAction<KategoriPanganState>) => {
            state.kelompokPangan = action.payload;
        },
        onGetKelompokPanganMentahVarian: (state, action: PayloadAction<KategoriPanganVarianState>) => {
            state.panganMentahVarian = action.payload;
        },
        onGetKaloriMentah: (state, action: PayloadAction<string>) => {
            state.getValueKaloriMentah = action.payload;
        },
        onGetKalMentah: (state, action: PayloadAction<number>) => {
            state.getKalMentah = action.payload;
        },
        onGetKalMentahProduct: (state, action: PayloadAction<number>) => {
            state.getKalMentahProduct = action.payload;
        },
        onGetValKategori: (state, action: PayloadAction<string>) => {
            state.getValueKategori = action.payload;
        },
        onHandleSection: (state, action: PayloadAction<string>) => {
            state.section = action.payload;
        },
        onClearFields: (state, action: PayloadAction<{ name: keyof ProductVarianState }>) => {
            const { name } = action.payload;
            if (isKeyofProductVarianVendor(name)) {
                if (typeof state[name] === 'string') {
                    (state[name] as string) = '';
                } else if (typeof state[name] === 'number') {
                    (state[name] as number) = 0;
                } else if (Array.isArray(state[name])) {
                    (state[name] as unknown as any[]) = [];
                }
            }
        },
        setIsOpenSuccesEditProduct: (state, action: PayloadAction<boolean>) => {
            state.isOpenSuccesEditProduct = action.payload;
        },
        onGetNameUpdateProduct: (state, action: PayloadAction<string>) => {
            state.getNameUpdateProduct = action.payload;
        },
    },
});

export const AddProductVarianService = { action: AddProductVarianSlice.actions };
const AddProductVarianReducer = AddProductVarianSlice.reducer;
export default AddProductVarianReducer;

export const isKeyofProductVarianVendor = (key: any): key is keyof ProductVarianState => {
    return [
        'nameProduct',
        'categoryMain',
        'categorySecond',
        'categoryThird',
        'dataFiles',
        'dataPreviews',
        'shortDescription',
        'longDescription',
        'panjangProduct',
        'lebarProduct',
        'tinggiProduct',
        'beratProduct',
        'minOrderan',
        'priceProduct',
        'priceDiscount',
        'minBuying',
        'productStock',
        'nameVarian',
    ].includes(key);
};
